import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createReport } from '../../store/actions/reportActions'
import { Redirect } from 'react-router-dom'
import Navbar from '../../components/layout/Navbar'
import Footer from '../../components/layout/Footer'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Resizer from 'react-image-file-resizer';
import { BallBeat } from 'react-pure-loaders';
import { clean } from '../../store/actions/reportActions'



class CreateReport extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        load: "Cargar fotos galería",
        loadReport: "Crear",
        disabled: false,
        name: '',
        date: '',
        url3D: '',
        idProject: this.props.idProject,
        urlVideo:'',
        img_360:'',
        gallery_photos:'',
        gallery: '',
        zip: '',
        loadingReport: false,
        classBtn: "btn disabled",
        classBtnZip: "btn btn-login"
      }
    }

  handleChangeName = (e) => {
      this.setState({
        [e.target.id]: e.target.value

      })
  }


  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
      classBtn: "btn btn-login",
      loadReport: "CREAR" 
    })
  }

  // handleChargeFile = (e) => {
  //   e.preventDefault();
  //   this.setState({ img_360: e.target.files[0] });
  // }

  handleChargeZip = (e) => {
    e.preventDefault();
    this.setState({ zip: e.target.files[0] });
  }

  handleChangeDate = (date) => {
    this.setState({
      date: date
    });
  }

  handleChargeFileMultiple = (e) => {
    e.preventDefault();
    this.setState({ 
      loading : true,
      load: "Comprimiendo galería, espere un momento...", 
      classBtnZip: "btn disabled",
      disabled: !this.state.disabled,
      classBtn: "btn disabled",
    })

    var fileReportGallery=[];
    for(var i=0; i < e.target.files.length; i++){
        Resizer.imageFileResizer(   
          e.target.files[i],
          1000,
          1000,
          'PNG',
          90,
          0,
          uri => {
              fileReportGallery.push(uri);
              if(fileReportGallery.length === i){
                this.setState({ 
                  load: "Galería comprimida",
                  loading: false,
                  classBtn: "btn btn-login",
                  classBtnZip: "btn btn-login",
                  disabled: false,
                  gallery: "yes",
                  loadReport: "CREAR" 
                });
              }
          },
          'blob'
          
      );
    }
    this.setState({ 
      gallery_photos: fileReportGallery
    });
  }


  handleSubmit = (e) => {
    e.preventDefault();
    if(this.state.urlVideo !== '' || this.state.img_360 !== '' || this.state.url3D !== '' || this.state.gallery_photos !== '' ){
      this.props.createReport(this.state);
      this.setState({ 
        loadReport: "Creando reporte, espere un momento...",
        loadingReport: true,
        classBtn: "btn disabled",
        classBtnZip: "btn disabled"
      });
    } else {
      this.setState({ 
        loadReport: "Faltan campos por llenar",
        classBtn: "btn disabled"
      });
    }
    if((this.state.urlVideo !== '' || this.state.img_360 !== '' || this.state.url3D !== '') && this.state.gallery_photos === '' ){
      this.props.history.push('/reportes/'+ this.props.idProject)
    }
  }


componentDidUpdate(){
  if(this.state.zip === '' && this.props.successGallery !== null){
      this.props.history.push('/reportes/'+ this.props.idProject)
      this.props.clean();
  } if (this.state.zip !== '' && this.props.successGallery !== null && this.props.successZip !== null) {
      this.props.history.push('/reportes/'+ this.props.idProject)
      this.props.clean();
  }
}

    
  render() {
    const { auth } = this.props;
    if (!auth.uid || (auth.uid !=="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/admin' /> 
    return (
      <div className="adminBody">
         <Navbar/>
          <div className="container">
          <div className="row">
            <div className="col s12 m10 offset-m1 add-icon">
            <form className="white" onSubmit={this.handleSubmit}>
              <i class="fas fa-file"></i>
              <h5 className="titleFormCreate">Crear un nuevo reporte</h5>
              <div className="input-field row">
                <input type="text" id='name' onChange={this.handleChangeName} required/>
                <label htmlFor="name">Nombre</label>
              </div>
              <div className="input-field row">
                <DatePicker required dateFormat="dd/MM/yyyy" selected={this.state.date} onChange={this.handleChangeDate}  placeholderText="fecha del reporte" />
              </div>
              <div className="input-field row">
                <input type="text" id='url3D' onChange={this.handleChange}  disabled = {(this.state.disabled)? "disabled" : ""}/>
                <label htmlFor="url3D">Url 3D</label>
              </div>
              <div className="input-field row">
                <input type="text" id='urlVideo' onChange={this.handleChange}  disabled = {(this.state.disabled)? "disabled" : ""}/>
                <label htmlFor="urlVideo">Url Video</label>
              </div>
              <div className="input-field row">
                <input type="text" id='img_360' onChange={this.handleChange}  disabled = {(this.state.disabled)? "disabled" : ""} />
                <label htmlFor="img_360">Url 360°</label>
              </div>
              {/* <div className="file-field input-field">
                  <div className={this.state.classBtnZip}>
                    <span>Cargar fotografía 360</span>
                    <input type="file" accept="image/x-png,image/gif,image/jpeg" onChange={this.handleChargeFile}/>
                  </div>
                  <div className="file-path-wrapper">
                    <input placeholder="Carga un archivo" readOnly className="file-path validate" type="text" />
                    <span>Nombre del Archivo:</span>
                  </div>
              </div> */}
              <div className="file-field input-field">
                  <div className={this.state.classBtnZip}>
                    <span>{this.state.load}</span>
                    <input type="file" multiple accept="image/x-png,image/gif,image/jpeg" onChange={this.handleChargeFileMultiple}/>
                  </div>
                  <div className="file-path-wrapper">
                    <input  placeholder="Carga uno o más archivos" readOnly className="file-path validate" type="text" />
                    <span>Nombre del/los  Archivo:</span>
                  </div>
                  <div className="center">
                    <BallBeat
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                  </div>
              </div>
              <div className="file-field input-field">
                  <div className={this.state.classBtnZip}>
                    <span>Cargar descargable</span>
                    <input type="file" accept="application/x-zip-compressed" onChange={this.handleChargeZip}/>
                  </div>
                  <div className="file-path-wrapper">
                    <input placeholder="Carga un archivo zip" readOnly className="file-path validate" type="text" />
                    <span>Nombre del Archivo:</span>
                  </div>
              </div>
              <div className="input-field center">
                <button className={this.state.classBtn}>{this.state.loadReport}</button>
              </div>
              <div className="center">
                    <BallBeat
                        color={'#123abc'}
                        loading={this.state.loadingReport}
                      />
              </div>
            </form>
            </div>
            </div>
          </div>
          <Footer/>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
const idproject =  ownProps.match.params.id
  return {
    auth: state.firebase.auth,
    idProject: idproject,
    successGallery: state.report.successGallery,
    successZip: state.report.successZip
  }
  
}

const mapDispatchToProps = dispatch => {
  return {
    createReport: (report) => dispatch(createReport(report)),
    clean: () => dispatch(clean())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateReport)
