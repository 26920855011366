import { storage } from "../../config/fbConfig";

export const createReport = (report) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    firestore.collection('reports').add({
      name: report.name,
      date: report.date,
      url3D: report.url3D,
      urlVideo: report.urlVideo,
      idProject: report.idProject,
      img_360: report.img_360,
      zip: "",
      gallery: report.gallery
    }).then((docId) => {
      dispatch({ type: 'CREATE_REPORT_SUCCESS' });
      gallery(report, docId.id, firestore, dispatch)
      if (report.zip!== '') {
       zip (report, docId.id, firestore, dispatch)
      }
      // if (report.img_360 !== '') {
      //  img360(report, docId.id, firestore, dispatch)
      // }
    }).catch(err => {
      dispatch({ type: 'CREATE_REPORT_ERROR' }, err);
    });
  }
};

export const deleteReport = (report) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('reports').doc(report.id).delete();
  }
};


// 360
// const img360 = (async(report, docId, firestore, dispatch) => {
//   try {
//     const storageRef = storage.ref("photo_360_report/")
//     await storageRef.child(docId + '.jpg').put(report.img_360).then((snapshot)=> {
//       snapshot.ref.getDownloadURL().then((downloadURL) => {
//         firestore.collection('reports').doc(docId).update({
//           img_360: downloadURL
//         }).then(()=>{
//           dispatch({type: 'SET_URL_360'})
//         }).catch(error => {
//           console.error('error con el dispacth', error)
//         })
//       }).catch(error => {
//         console.error('error en firestore', error)
//       })
//     }).catch(error => {
//       console.error('error en storage', error)
//     })
//   } catch (error) {
//     console.error('Error guardando 360', error);
//   }
// })


// gallery 
const gallery = (async (report, docId, firestore, dispatch) => {
  try {
    // var photo_counter = 0;
    const storageRef= storage.ref("photo_gallery_report/")
    for(var i=0; i<report.gallery_photos.length; i++){
      await storageRef.child(docId+ "/" + docId + i)
      .put(report.gallery_photos[i])
      //promesa 1
      .then((snapshot)=> {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          firestore.collection('reports').doc(docId).collection('photos_gallery').add({
            downloadURL
            // promesa 2
          }).then(()=>{
            if(i===(report.gallery_photos.length)){
              dispatch({type: 'SET_URL_REPORT_GALLERY'})
              console.log("se ejecuta dispacth")
            }
          }).catch(error => {
            console.error('error con el dispacth', error)
          })
        });
      }).catch(error => {
        console.error("error con firestore", error)
      })
    }
  } catch (error) {
    console.error('Error creando galeria', error);
  }
});


// zip 
const zip= (async(report, docId, firestore, dispatch) => {
  try {
    const storageRef= storage.ref("photo_gallery_report/")
    storageRef.child(docId + '.zip').put(report.zip).then((snapshot)=> {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        firestore.collection('reports').doc(docId).update({
          zip: downloadURL
        }).then(()=>{
          dispatch({type: 'SET_URL_ZIP'})
        }).catch(error => {
          console.error('error con el dispacth', error)
        })
      }).catch(error => {
        console.error('error con firestore', error)
      })
    }).catch(error => {
      console.error('error con storage', error)
    })
  }catch (error) {
    console.error('Error creando zip', error);
  }
})
//edit report

export const editReport = (report) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    if (report.name !== '') {
      firestore.collection('reports').doc(report.id).update({
        name: report.name
      }).then(() => {
        dispatch({ type: 'CREATE_REPORT_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CREATE_REPORT_ERROR' }, err);
      });  
    }
    if (report.date !== '') {
      firestore.collection('reports').doc(report.id).update({
        date: report.date,
      }).then(() => {
        dispatch({ type: 'CREATE_REPORT_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CREATE_REPORT_ERROR' }, err);
      });  
    }
    if (report.url3D !== '') {
      firestore.collection('reports').doc(report.id).update({
        url3D: report.url3D
      }).then(() => {
        dispatch({ type: 'CREATE_REPORT_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CREATE_REPORT_ERROR' }, err);
      });  
    }
    if (report.urlVideo !== '') {
      firestore.collection('reports').doc(report.id).update({
        urlVideo: report.urlVideo
      }).then(() => {
        dispatch({ type: 'CREATE_REPORT_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CREATE_REPORT_ERROR' }, err);
      });  
    }
    if (report.img_360 !== '') {
      firestore.collection('reports').doc(report.id).update({
        img_360: report.img_360,
      }).then(() => {
        dispatch({ type: 'CREATE_REPORT_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CREATE_REPORT_ERROR' }, err);
      }); 
    }
    // if (report.gallery_photos.length !== 0) {
    //   gallery_update(report, report.id, firestore)
    // }
    if (report.zip.length !== 0) {
      zip(report, report.id, firestore, dispatch)
    }
  }
}


export const clean= () => {
  return (dispatch) => {
      dispatch({ type: 'SUCCESS_CLEAN' }); 
  }
};