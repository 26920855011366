import React from 'react'
import ProjectSummary from './ProjectSummary'
import { Link } from 'react-router-dom'
import { Icon } from 'react-materialize'
import ModalDeleteProject from '../modal/ModalDeleteProject'

const ProjectList = ({props, textBuscar}) => {
 var sortedProjects = []
 if(props.projects !== undefined){
  for(var i=0; i< props.projects.length; i++){
    if(props.projects[i].value === 'ABIERTO'){
      sortedProjects.push(props.projects[i])
    }
  }
  for(var i=0; i< props.projects.length; i++){
    if(props.projects[i].value === 'CERRADO'){
      sortedProjects.push(props.projects[i])
    }
  }
 }
  return (
    <ul className="client-list">
      { sortedProjects && sortedProjects.map(project => {
        if(project.name.includes(textBuscar.toUpperCase()) || project.value.includes(textBuscar.toUpperCase())){
          return (
            <li className="li-list" key={project.id}>
              <Link to={'/proyectos-detalle/' + project.id} >
                <ProjectSummary project={project}/>
              </Link>
              <Link to= {'/editarproyecto/'+ props.idCliente + '&' + project.id}>
                <div className="div_edit edit_class">
                  <Icon>create</Icon>
                </div>
              </Link>
              <ModalDeleteProject project={project}/>
            </li>
        )
        }
      })}  
    </ul>
  )
}

export default ProjectList
