import React from 'react';
import { connect } from 'react-redux';
import { deleteUser } from '../../store/actions/userActions'
import { Icon } from 'react-materialize'
import ReactModal from 'react-modal';


class ModalDeleteUser extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showModal: false,
      user: this.props.user
    };
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  
  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }

  handleClick = (e) => {
    e.preventDefault();
    this.props.deleteUser(this.state.user)
    this.setState({ showModal: false });
  }

  
  render () {
    return (
      <div>
         <div className="div_delete">
            <a className="modal_trigger delete_class" onClick={this.handleOpenModal}><Icon>delete</Icon></a>
        </div>
      
        <ReactModal 
           isOpen={this.state.showModal}
           contentLabel="onRequestClose"
           onRequestClose={this.handleCloseModal}
        >
                 <div className="" key={this.state.user.uid + '-modal'}>
                    <div className="">
                      <h5 className="title_access">¿Está seguro que desea eliminar este Usuario: {this.state.user.name} ?</h5>
                    </div>
                    <div className="right">
                      <a className="btn btn-login" onClick={this.handleClick}>Eliminar</a>
                    </div>
                  </div>
         
        </ReactModal>
      </div>
    );
  }
}
    
  const mapStateToProps = (state) => {
    return{
      authError: state.auth.authError
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      deleteUser: (creds) => dispatch(deleteUser(creds))
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteUser)
