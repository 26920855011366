import React from 'react'
import ReportSummary from './ReportSummary'
import { Link } from 'react-router-dom'
import { Icon } from 'react-materialize'
import ModalDeleteReport from '../modal/ModalDeleteReport'
import moment from 'moment'

const ReportList = ({props, textBuscar}) => {
  var sortedActivities = null
  if(props.reports !== undefined){
    sortedActivities = props.reports.sort((a, b) => b.date.seconds - a.date.seconds)
  }
 
  
  return (
    <ul className="client-list">
      { sortedActivities&& sortedActivities.map(report => {
         if(report.name.includes(textBuscar.toUpperCase()) || moment(report.date.toDate()).format("DD-MM-YYYY").includes(textBuscar.toUpperCase())){
            return (
                <li className="li-list" key={report.id}>
                    <ReportSummary report={report}/>
                  <Link to= {'/editareporte/' + props.idProject + '&' + report.id}>
                    <div className="div_edit edit_class">
                      <Icon>create</Icon>
                    </div>
                  </Link>
                  <ModalDeleteReport report={report}/>
                </li>
            )
         }
      })}  
    </ul>
  )
}

export default ReportList