import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Dashboard from './components/dashboard/Dashboard';
import ClientDetails from './components/clients/ClientDetails';
import Clientes from './components/auth/Clientes';
import Admin from './components/auth/Admin';
import CreateProject from './components/projects/CreateProject';
import CreatePiloto from './components/pilotos/CreatePiloto';
import CreateClient from './components/clients/CreateClient';
import EditClient from './components/clients/EditClient';
import EditProject from './components/projects/EditProject';
import EditReport from './components/reports/EditReport';

import EditPiloto from './components/pilotos/EditPiloto';

import EditUser from './components/users/EditUser';
import CreateReport from './components/reports/CreateReport';
import CreateUser from './components/users/CreateUser';
import DashboardProjects from './components/dashboard/DashboardProjects';
import ProjectOptions from './components/projects/ProjectOptions';
import DashboardReports from './components/dashboard/DashboardReports';
import DashboardUsers from './components/dashboard/DashboardUsers';
// import DashboardPilotos from './components/dashboard/DashboardPilotos'
import DashboardProjectsUser from './components/dashboard-users/DashboardProjectsUser';
import DashboardReportsUser from './components/dashboard-users/DashboardReportsUser';
import ProjectDetailsUser from './components/projects-users/ProjectDetailsUser';
import ProjectsUserClose from './components/projects-users/ProjectsUserClose';
import ReportDetailsUser from './components/reports-users/ReportDetailsUser'
import ReportsUserClose from './components/reports-users/ReportsUserClose';

import DashboardPilotosUser from './components/dashboard-users/DashboardPilotosUser';
import PilotoDetailsUser from './components/pilotos-users/PilotoDetailsUser';
import PilotoUserClose from './components/pilotos-users/PilotoUserClose';
import MapaPilotos from './components/pilotos-users/MapaPilotos';
import MapaPilotosClose from './components/pilotos-users/MapaPilotosClose';

import MapaProjects from './components/projects-users/MapaProjects';
import MapaProjectsClose from './components/projects-users/MapaProjectsClose';
import ErrorBoundary from './ErrorBoundary'
 
 
 
 
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ErrorBoundary>
          <div className="App">
              <Switch>
                <Route exact path='/'component={Clientes} />
                <Route exact path='/proyectos'component={DashboardProjectsUser} />
                <Route exact path='/pilotos/:id'component={DashboardPilotosUser} />
                <Route exact path='/mapa-proyectos/:id'component={MapaProjects}/>
                <Route exact path='/mapa-proyectos-cerrados/:id'component={MapaProjectsClose} />
                <Route exact path='/proyectos-cerrados'component={ProjectsUserClose} />
                <Route exact path='/entregables'component={DashboardReportsUser} />
                <Route exact path='/entregables-cerrados'component={ReportsUserClose} />
                <Route exact path='/dashboard'component={Dashboard} />
                <Route path='/cliente/:id' component={ClientDetails} />
                <Route path='/proyectos-detalle/:id' component={ProjectOptions} />
                <Route path='/proyectos/:id' component={DashboardProjects} />
                <Route path='/usuarios/:id' component={DashboardUsers} />
                <Route path='/reportes/:id' component={DashboardReports} />
                {/* <Route path='/pilotos/:id' component={DashboardPilotos} /> */}
                <Route path='/clientes' component={Clientes} />
                <Route path='/admin' component={Admin} />
                <Route path='/crearproyecto/:id' component={CreateProject} />
                <Route path='/crearusuario/:id' component={CreateUser} />
                <Route path='/crearcliente' component={CreateClient} />
                <Route path='/editarcliente/:id' component={EditClient} />
                <Route path='/editarproyecto/:id' component={EditProject} />
                <Route path='/crear-reporte/:id' component={CreateReport} />

                <Route path='/editar-piloto/:id' component={EditPiloto} />

                <Route path='/crear-piloto/:id' component={CreatePiloto} />
                <Route path='/editareporte/:id' component={EditReport} />
                <Route path='/editarusuario/:id' component={EditUser} />
                <Route path='/proyecto/:id' component={ProjectDetailsUser}/>
                <Route path='/entregable/:id' component={ReportDetailsUser} />
                <Route path='/piloto/:id' component={PilotoDetailsUser}/>
                <Route exact path='/pilotos-cerrados/:id'component={PilotoUserClose} />
                <Route exact path='/mapa-pilotos/:id'component={MapaPilotos}/>
                <Route exact path='/mapa-pilotos-cerrados/:id'component={MapaPilotosClose} />
              </Switch>
            </div>
         </ErrorBoundary>
      
      </BrowserRouter>
    );
  }
}
 
export default App;
 

