import React, { Component } from 'react'
import ReportList from '../reports/ReportList'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Navbar from '../../components/layout/Navbar'
import Footer from '../../components/layout/Footer'
import { Link } from 'react-router-dom'
import { Icon } from 'react-materialize'
import { Button } from 'react-materialize'


class DashboardReports extends Component {

  
  state = {
    textBuscar: ''
  }


  filter(event){
    this.setState({
      textBuscar: event.target.value
    })
  }
 
  render() {
   
    const { auth, idProject, nameProject} = this.props;
   
    if (!auth.uid || (auth.uid !=="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/admin' /> 

    return (
      <div className="adminBody">
        <Navbar/>
        <div className="dashboard container">
          <div className="row">
            <div className="col s8 m8 offset-m1 add-icon">
              <i class="fas fa-building"></i>
              <h4 className="clients">{nameProject + ': REPORTES'}</h4>
            </div>
            <div className="col s3 col m2 add-icon right">
                <Link to={'/crear-reporte/' + idProject} key={idProject}>
                  <Button
                    floating
                    large
                    className="plus_client"
                    waves="light"
                    icon={  <Icon>
                      add
                      </Icon>}
                  />
                </Link>
            </div>
          </div>
          <div className="row div_ul">
            <input className="form-control"  placeholder="Buscar..." value={this.state.text} onChange={(text) => this.filter(text)}/> 
          </div> 
          <div className="">
            <div className="row div_ul">
              <ReportList props={this.props} textBuscar={this.state.textBuscar}/>
            </div>
          </div>
        </div>
        <Footer/>
      </div>    
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const idproject =  ownProps.match.params.id;
  const projects = state.firestore.ordered.projects;
  var nameProject = null;
  if(projects){ 
    for(var i=0; i<projects.length; i++){
        if(projects[i].id === idproject){
          nameProject = projects[i].name
        }
      }
  }
  return {
    reports: state.firestore.ordered.reports,
    auth: state.firebase.auth,
    idProject: idproject,
    nameProject: nameProject
  }
  
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    { collection: 'reports', 
    where: [
      ['idProject', '==', props.idProject]
    ]
    }
  ])
)(DashboardReports)

