import React, { useState, useEffect  } from 'react'
import { Button } from 'react-materialize'
import { Icon } from 'react-materialize'


const EditRecorrido =({props, pilotos}) => {

 const  handleRecorridoUrl  = props;
 
  const [urlRecorridos, setUrlRecorridos] = useState([]);  
  const [urlRecorridosPrev, setUrlRecorridosPrev] = useState([]); 
  const [urlRecorridosTotal, setUrlRecorridosTotal] = useState([]); 
  
  const addReccoridosTotal = () =>{
    setUrlRecorridosTotal(urlRecorridos.concat(urlRecorridosPrev));

  }
  const addInputButton = (e) => {
      e.preventDefault()
      setUrlRecorridos([ ... urlRecorridos, ""]) 
  }

  const handleChangeVideo = (e, index) => {
    urlRecorridos[index] = e.target.value;
    setUrlRecorridos([ ... urlRecorridos]);
  }
  
  const handleDeleteInputRecorrido = (position) =>{
    setUrlRecorridos((urlRecorridos) => urlRecorridos.filter((_, index) => index !== position));
  
  }
  const handleDeleteInputRecorridoPrev = (position) =>{
    setUrlRecorridosPrev((urlRecorridosPrev) => urlRecorridosPrev.filter((_, index) => index !== position));  
 
}

useEffect(() => {
    setUrlRecorridosPrev(pilotos);
}, [pilotos]); 

    useEffect(() => {
        addReccoridosTotal()
    }, [ urlRecorridos]); 

    useEffect(() => {
        addReccoridosTotal()
    }, [ urlRecorridosPrev]); 

    useEffect(() => {
        handleRecorridoUrl(urlRecorridosTotal);
    }, [urlRecorridosTotal]); 


  return (

      <div className="box_edits_inputs_admin">
            <label className="title_edit_admin">Url de Recorrido Virtual</label>
            {urlRecorridosPrev.map((urlR, index) =>(          
                urlR ? 
                <div key={index +'urlR'} style={{display:'flex'}}>
                    <input className="input_edit_admin" disabled type="text" name="urlR" value={urlR} id={`urlR-${index+1}`} placeholder={`urlR-${index+1}`}/>
                    <Button className="btn_delete_admin"
                        onClick={() => {handleDeleteInputRecorridoPrev(index)}}
                        icon={  <Icon>
                                  delete
                                </Icon>}
                    />
                </div>
                : null  
            ))}
            <div className="add_item">
            <Button 
                onClick={addInputButton}
                floating
                large
                className="plus_client"
                waves="light"
                icon={  <Icon>
                            add
                        </Icon>}
            />
            
            <label>Añadir nuevos recorridos virtuales </label>
            </div>

            {urlRecorridos.map((urlRecorrido, index) =>(
                <div key={index} style={{display:'flex'}}>
                    <input className="input_edit_admin" type="text" name="urlRecorrido" value={urlRecorrido} id={`urlRecorrido-${index+1}`} placeholder={`urlRecorrido-${index+1}`} onChange={(e)=> handleChangeVideo(e, index)}/>
                    {/* <label htmlFor="urlVideo"></label> */}
                    <Button className="btn_delete_admin"
                        onClick={() => {handleDeleteInputRecorrido(index)}}
                        icon={  <Icon>
                                  delete
                                </Icon>}
                    />
                </div>
  
            ))}
           
          
      </div>
     
  )
  

}

export default EditRecorrido