import React from 'react';

const Footer = () => {
    let copyright = new Date();
    let update = copyright.getFullYear();

    return (
        <footer className="page-footer footer-fixed">
            <div className="footer-copyright">
                <div className="containerFooter">
                     Copyright ©{update} All rights reserved BIMO
                
                </div>
            </div>
        </footer>
     
    )
  }
  
  export default Footer