import React, { Component } from 'react'
import { connect } from 'react-redux'
import { editReport } from '../../store/actions/reportActions'
import { Redirect } from 'react-router-dom'
import Navbar from '../../components/layout/Navbar'
import Footer from '../../components/layout/Footer'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import { BallBeat } from 'react-pure-loaders';
import { clean } from '../../store/actions/reportActions'

class EditReport extends Component {
  state = {
    name: '',
    date: '',
    url3D: '',
    urlVideo:'',
    img_360:'',
    // gallery_photos:'',
    id: this.props.id,
    zip: '',
    loadingReport: false,
    loadReport: "Actualizar",
    classBtn: "btn btn-login"
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  // handleChargeFile = (e) => {
  //   e.preventDefault();
  //   this.setState({ img_360: e.target.files[0] });
  // }

  handleChargeZip = (e) => {
    e.preventDefault();
    this.setState({ zip: e.target.files[0] });
  }

  handleChangeDate = (date) => {
    this.setState({
      date: date
    });
  }

  // handleChargeFileMultiple = (e) => {
  //   e.preventDefault();
  //   var fileReportGallery=[];
  //   for(var i=0; i < e.target.files.length; i++){
  //     fileReportGallery.push(e.target.files[i]);
  //   }
  //   this.setState({ gallery_photos: fileReportGallery });
  // }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.editReport(this.state);
    this.setState({ 
      loadReport: "Actualizando reporte, espere un momento...",
      loadingReport: true,
      classBtn: "btn disabled",
    });
    if(this.state.zip === ''){
      this.props.history.push('/reportes/'+ this.props.idProject);
    }

  }

  componentDidUpdate(){
    if(this.state.zip !== '' && this.props.successZip !== null){
        this.props.clean();
        this.props.history.push('/reportes/'+ this.props.idProject)
    
    } 
  }

  render() {
    const { auth, report } = this.props;
    if (!auth.uid || (auth.uid !=="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/admin' /> 
    
    return (
      <div className="adminBody">
         <Navbar/>
          <div className="container">
          <div className="row">
            <div className="col s12 m10 offset-m1 add-icon">
            <form className="white" onSubmit={this.handleSubmit}>
              <i class="fas fa-file"></i>
              <h5 className="titleFormCreate">EDITAR {report.name}</h5>
              <div className="input-field row">
                <input type="text" id='name' onChange={this.handleChange} />
                <label htmlFor="name">{report.name}</label>
              </div>
              <div className="input-field row">
                <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.date} onChange={this.handleChangeDate}  placeholderText={moment(report.date.toDate()).format("DD-MM-YYYY")} />
              </div>
              <div className="input-field row">
                <input type="text" id='url3D' onChange={this.handleChange} />
                <label htmlFor="url3D">{report.url3D ? report.url3D : "Url 3D"}</label>
              </div>
              <div className="input-field row">
                <input type="text" id='urlVideo' onChange={this.handleChange} />
                <label htmlFor="urlVideo">{report.urlVideo ? report.urlVideo : "Url Video"}</label>
              </div>
              <div className="input-field row">
                <input type="text" id='img_360' onChange={this.handleChange} />
                <label htmlFor="img_360">{report.img_360 ? report.img_360 : "Url 360°"}</label>
              </div>
              {/* <div className="file-field input-field">
                  <div className="btn btn-login">
                    <span>Cambiar fotografía 360</span>
                    <input type="file" accept="image/x-png,image/gif,image/jpeg" onChange={this.handleChargeFile}/>
                  </div>
                  <div className="file-path-wrapper">
                    <input placeholder="Carga un archivo"  readOnly className="file-path validate" type="text" />
                    <span>Nombre del Archivo:</span>
                  </div>
              </div> */}
              {/* <div className="file-field input-field">
                  <div className="btn btn-login">
                    <span>Añadir fotos galería</span>
                    <input type="file" multiple accept="image/x-png,image/gif,image/jpeg" onChange={this.handleChargeFileMultiple}/>
                  </div>
                  <div className="file-path-wrapper">
                    <input placeholder="Carga uno o más archivos" readonly="readonly" className="file-path validate" type="text" />
                    <span>Nombre del/los  Archivo:</span>
                  </div>
              </div> */}
              <div className="file-field input-field">
                  <div className="btn btn-login">
                    <span>Cambiar descargable</span>
                    <input type="file" accept="application/x-zip-compressed" onChange={this.handleChargeZip}/>
                  </div>
                  <div className="file-path-wrapper">
                    <input placeholder="Carga un archivo zip" readOnly className="file-path validate" type="text" />
                    <span>Nombre del Archivo:</span>
                  </div>
              </div>
              <div className="input-field center">
                <button className={this.state.classBtn}>{this.state.loadReport}</button>
              </div>
              <div className="center">
                    <BallBeat
                        color={'#123abc'}
                        loading={this.state.loadingReport}
                      />
              </div>
            </form>
            </div>
            </div>
          </div>
          <Footer/>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const idPR = ownProps.match.params.id;
  var res = idPR.split("&");
  const id = res[1];
  const idProject = res[0]
  const reports = state.firestore.data.reports;
  const report = reports ? reports[id] : null
  return {
    id : id,
    report: report,
    auth: state.firebase.auth,
    idProject: idProject,
    successZip: state.report.successZip,
    success360: state.report.success360
  }
}

const mapDispatchToProps = dispatch => {
  return {
    editReport: (report) => dispatch(editReport(report)),
    clean: () => dispatch(clean())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditReport)
