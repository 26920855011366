import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
//import slick carousel
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


class GalleryPiloto extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.setState({
        nav1: this.slider1,
        nav2: this.slider2
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  
  render() {
    const { gallery_pilotos } = this.props;
    const navSettings = {
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 425,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    
    }
    return (
      <div>
        <Slider
          asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)}
          className="firstSlide"
        >
           { gallery_pilotos && gallery_pilotos.map(url => {
                    return(
                      <div key={url.id}>
                        <div className="imagenCarousel" style={{ backgroundImage : `url(${url.downloadURL})` }} title={url.id}></div>
                      </div>
                      )
                    })
              }
        </Slider>
        <Slider {...navSettings}
          asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}
          slidesToShow={6}
          swipeToSlide={true}
          focusOnSelect={true}
          className="secondSlide"
        >
           { gallery_pilotos && gallery_pilotos.map(url => {
                    return(
                      <div key={url.id}>
                        <div className="imagenCarousel" style={{ backgroundImage : `url(${url.downloadURL})` }} title={url.id}></div>
                      </div>
                      )
                    })
              }
        </Slider>
      </div>
    );
  }
  }




const mapStateToProps = (state, props) => {
  const gallery_pilotos = state.firestore.ordered.gallery_pilotos
  // console.log(props)
  return {
    auth: state.firebase.auth,
    id: props.piloto.id,
    gallery_pilotos: gallery_pilotos
  }
}

const gallery_pilotos = 'gallery_pilotos';

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [{
    collection: 'pilotos',
    doc: props.id,
    subcollections: [{ collection: 'photos_gallery' }],
    storeAs: gallery_pilotos
  }])
)(GalleryPiloto)
