import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createUser } from '../../store/actions/userActions'
import { Redirect } from 'react-router-dom'
import Navbar from '../../components/layout/Navbar'
import Footer from '../../components/layout/Footer'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'


class CreateUser extends Component {
  state = {
    name: '',
    idCliente: this.props.idcliente,
    idProject:this.props.projects,
    uid: ''
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
    var projects_users=[];
    if(this.props.projects){
      for(var i=0; i < this.props.projects.length; i++){
        projects_users.push(this.props.projects[i]);
      }
      this.setState({ idProject: projects_users });
    }   
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.createUser(this.state);
    this.props.history.push('/usuarios/' + this.state.idCliente);
  }

  render() {
    const { auth } = this.props;
    if (!auth.uid || (auth.uid !=="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/admin' />  
    return (
      <div className="adminBody">
         <Navbar/>
          <div className="container">
          <div className="row">
            <div className="col s12 m10 offset-m1 add-icon">
            <form className="white" onSubmit={this.handleSubmit}>
              <i class="fas fa-user-circle"></i>
              <h5 className="titleFormCreate">Crear un nuevo usuario</h5>
              <div className="input-field row">
                <input type="text" id='name' onChange={this.handleChange} required />
                <label htmlFor="name">Email</label>
              </div>
              <div className="input-field row">
                <input type="password" id='uid' onChange={this.handleChange} required />
                <label htmlFor="uid">Contraseña</label>
              </div>
              <div className="input-field center">
                <button className="btn btn-login">Crear</button>
              </div>
            </form>
            </div>
            </div>
          </div>
          <Footer/>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
const idcliente =  ownProps.match.params.id;
  return {
    auth: state.firebase.auth,
    idcliente: idcliente,
    projects: state.firestore.ordered.projects,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createUser: (user) => dispatch(createUser(user))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [
    { collection: 'projects', 
    where: [
      ['idCliente', '==', props.idcliente]
    ]
    }
  ])
  )(CreateUser)
