import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
// import { Button } from 'react-materialize'
import NavbarUser from '../../components/layout/NavbarUser'
// import BasicDatePicker from './BasicDatePicker';
import FooterUser from '../layout/FooterUser'
import ReportView from './ReportView';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import "moment/locale/es";
 
moment.locale("es");
 
class ProjectDetailsUser extends Component {
 
  state = {
    selectedDate: null
  }
 
  handleDateChange = date => {
    this.setState({ selectedDate: date })
  }
  
 
    render() {    
      const { auth, project, reports } = this.props;
      const { selectedDate } = this.state;
      if (!auth.uid || (auth.uid ==="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/' /> 
      if(reports && reports.length > 0 && project){
        var dates = [];
        for(var i=0; i<reports.length;i++){
          dates.push(new Date(moment(reports[i].date.toDate()).format("YYYY-MM-DD")));
        }
        var actualDate=new Date(Math.max.apply(null, dates)); 
        var olderDate = new Date(Math.min.apply(null, dates));
  
        return(
          <div className="userBody">
              <NavbarUser/>
              <div className="ImgPage" style={{ backgroundImage : `url(${project.img_project})` }}>
                  <div className="capaImgPage"></div>
                  <div className="boxTextImgPage">
                    <h3>{project.name}</h3>
                    <h6>{project.place}</h6>
                  </div>
              </div>
              <div className="container">
                <div className="boxDatePicker">
                  <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                    <DatePicker
                        views={["year", "month"]}
                        label="Fecha de Reporte"
                        minDate={olderDate}
                        maxDate={actualDate}
                        value={selectedDate ? selectedDate : actualDate}
                        onChange={this.handleDateChange}
                        minDateMessage=''
                    />
                 </MuiPickersUtilsProvider>
                </div>
                <ReportView date={this.state.selectedDate} reports={reports}/>
              </div>
              <FooterUser/> 
          </div>
        )
      }  else if(reports && reports.length <= 0 && project){ 
        return(
          <div className="userBody">
            <NavbarUser/>
            <div className="ImgPage" style={{ backgroundImage : `url(${project.img_project})` }}>
                  <div className="capaImgPage"></div>
                  <div className="boxTextImgPage">
                    <h3>{project.name}</h3>
                    <h6>{project.place}</h6>
                  </div>
              </div>
            <div className="mensajeNo">No hay reportes asociados a este proyecto</div>
            <FooterUser/> 
          </div>
        )
      } else {
        return(
          <div className="userBody">
            <div className="mensajeNo">Cargando...</div>
           <FooterUser/> 
          </div>
        )
      }
    }
}
 
 
const mapStateToProps = (state, ownProps) => {
  // console.log(ownProps)
  const id = ownProps.match.params.id;
  const projects = state.firestore.data.projects;
  const project = projects ? projects[id] : null;
  const reportsProject = state.firestore.ordered.reportsProject;
  const reports = reportsProject ? reportsProject : null;

  return{
      auth: state.firebase.auth,
      project: project,
      id: id,
      reports: reports
  }
}
 
const reports = 'reportsProject'
 
export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) =>[
        { collection: 'reports',
        where: [
            ['idProject', '==', props.id]
        ],
        storeAs: reports,
      },
      { collection: 'projects', doc: props.id }
    ])
  )(ProjectDetailsUser)
 

