import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import SignedInLinksUser from './SignedInLinksUser'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Navbar, Icon } from 'react-materialize'
import SignedInLinksUserNoPilotos from './SignedInLinksUserNoPilotos'


class NavbarUser extends Component {

  render() {

        const { client, pilotos } = this.props;

        const links = <SignedInLinksUser  client={client}/>;
        const links_no_pilotos = <SignedInLinksUserNoPilotos  client={client}/>;
        if(client && pilotos && pilotos.length !== 0){
            return (
                <Navbar className="white"
                    alignLinks="right"
                    brand={
                        <Link to='/'>
                            <img src={client.url_logo? client.url_logo : "./../img/logo.png"} className="brand-logos log_nav" alt='logo'/>
                        </Link>
                        }
                    id="mobile-nav"
                    menuIcon={<Icon>menu</Icon>}
                    options={{
                        draggable: true,
                        edge: 'left',
                        inDuration: 250,
                        onCloseEnd: null,
                        onCloseStart: null,
                        onOpenEnd: null,
                        onOpenStart: null,
                        outDuration: 200,
                        preventScrolling: false
                    }}
                >
                    {links}
                </Navbar>
            )
        } else if (client && pilotos && pilotos.length === 0) {
            return (
                <Navbar className="white"
                    alignLinks="right"
                    brand={
                        <Link to='/'>
                            <img src={client.url_logo? client.url_logo : "./../img/logo.png"} className="brand-logos log_nav" alt='logo'/>
                        </Link>
                        }
                    id="mobile-nav"
                    menuIcon={<Icon>menu</Icon>}
                    options={{
                        draggable: true,
                        edge: 'left',
                        inDuration: 250,
                        onCloseEnd: null,
                        onCloseStart: null,
                        onOpenEnd: null,
                        onOpenStart: null,
                        outDuration: 200,
                        preventScrolling: false
                    }}
                >
                    {links_no_pilotos}
                </Navbar>
            )
        }
        else {
            return (
                <nav className="nav-wrapper">
                    <div className="container">
                        <Link to='/clientes'>
                        <img alt='cangando...'/>
                        </Link>
                        {links}
                    </div>
                </nav>
            )
        }
       
        }
    }
  
  


const mapStateToProps = (state) => {

    const users = state.firestore.ordered.users;
    const user = users ? users[0] : null;
    const clientUser = state.firestore.ordered.cliente;
    const client = clientUser ? clientUser[0] : null;
    const idCliente = user ? user.idCliente : null
    const pilotos = state.firestore.ordered.pilotos;
   
    return{
        auth: state.firebase.auth,
        user: user,
        idCliente: idCliente,
        client: client,
        pilotos: pilotos
    }
}
const cliente = 'cliente'
const pilotos = 'pilotos'

export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) =>[
      { collection: 'users',
        where: [
            ['uid', '==', props.auth.uid]
        ]
      },
      { collection:'clients',
        doc: props.idCliente,
        storeAs: cliente
      },
      { collection: 'pilotos',
      where: [
          ['clientID', '==', props.idCliente]
      ],
      storeAs: pilotos,
    }
    ])
  )(NavbarUser)
