import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BtnFlotantContact from './BtnFlotantContact'
import { sendEmail } from '../../store/actions/emailActions'
import { close } from '../../store/actions/emailActions'
import { connect } from 'react-redux'


class ModalContact extends Component {
    constructor(props) {
        super(props);
        this.state = {
          fname: '',
          email: '',
          message: ''
        }
      }

      state = {
        open: false
      }

      handleFormSubmit = e => {
        e.preventDefault();
        this.props.sendEmail(this.state);
      }

      handleClickOpen = () => {
        this.setState({ 
            open: true
        })
      };

      handleClose = () => {
        this.setState({ 
            open: false,
            fname: '',
            email: '',
            message: ''
        })
        this.props.close();
      };

    render() {
      const { successContact } = this.props;
        return (
          <div>
            <div onClick={this.handleClickOpen}>
                <BtnFlotantContact />
            </div>
            <Dialog open={this.state.open ? this.state.open : false} onClose={this.handleClose} aria-labelledby="form-dialog-title" className="contactModal">
                <DialogTitle id="form-dialog-title">Contáctanos</DialogTitle>
                <DialogContent>
                <DialogContentText>
                  En caso de dudas o sugerencias, contáctanos:
                </DialogContentText>
                </DialogContent>
                <form onSubmit={e => this.handleFormSubmit(e)} >
                    <input required type="text" id="fname" name="firstname" placeholder="Tu nombre"
                      value={this.state.fname}
                      onChange={e => this.setState({ fname: e.target.value })}
                    />
                    <input required type="email" id="email" name="email" placeholder="Correo Electrónico" 
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value })}
                    />
                    <textarea required id="subject" name="subject" placeholder="Cuéntanos..."
                      onChange={e => this.setState({ message: e.target.value })}
                      value={this.state.message}
                    ></textarea>
                    <input type="submit"  value="Enviar"/>
                    <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                    Cerrar
                </Button>
                </DialogActions>
                </form>
                <div className="center red-text">
                      { successContact ? <p>{successContact}</p> : null }
                </div>
            </Dialog>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
  return {
    successContact: state.email.successContact,
    contactError: state.email.contactError
  }
}

const mapDispatchToProps = dispatch => {
  return {
    sendEmail: (email) => dispatch(sendEmail(email)),
    close: () => dispatch(close())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContact)