import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createProject } from '../../store/actions/projectActions'
import { Redirect } from 'react-router-dom'
import Navbar from '../../components/layout/Navbar'
import Footer from '../../components/layout/Footer'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
 
 
class CreateProject extends Component {
  state = {
    name: '',
    place: '',
    value: '',
    date: '',
    idCliente: this.props.idcliente,
    img_project:'',
    lat:'',
    long:''
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value.toUpperCase()
    })
  }
 
  handleChangeUbi = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
 
  handleChangeDate = (date) => {
    this.setState({
      date: date
    });
  }
 
  handleChargeFile = (e) => {
    e.preventDefault();
    this.setState({ img_project: e.target.files[0]});
  }
 
  handleChangeSelect = (e) => {
    this.setState({
      value: e.target.value
    });
  }
 
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.createProject(this.state);
    this.props.history.push('/proyectos/'+ this.state.idCliente);
  }
  render() {
    const { auth } = this.props;
    if (!auth.uid || (auth.uid !=="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/admin' /> 
    return (
      <div className="adminBody">
         <Navbar/>
          <div className="container">
            <div className="row">
            <div className="col s12 m10 offset-m1 add-icon">
            <form className="white" onSubmit={this.handleSubmit}>
              <i class="fas fa-building"></i>
              <h5 className="titleFormCreate">Crear un nuevo proyecto</h5>
              <div className="input-field row">
                <input type="text" id='name' onChange={this.handleChange} required/>
                <label htmlFor="name">Nombre</label>
              </div>
              <div className="input-field row">
                <input type="text" id='place' onChange={this.handleChange} required/>
                <label htmlFor="place">Ubicación</label>
              </div>
              <div className="input-field row">
                <input type="number" id='lat' onChange={this.handleChangeUbi} step="any"/>
                <label htmlFor="lat">Latitud</label>
              </div>
              <div className="input-field row">
                <input type="number" id='long' onChange={this.handleChangeUbi} step="any"/>
                <label htmlFor="long">Longitud</label>
              </div>
              <div className=" row">
                <select value={this.state.value} onChange={this.handleChangeSelect} className="browser-default" required >
                  <option value="">Selecciona el estado del proyecto</option>
                  <option value="ABIERTO">ABIERTO</option>
                  <option value="CERRADO">CERRADO</option>
                </select>            
              </div>
              <div className="input-field row">
                <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.date} onChange={this.handleChangeDate}  placeholderText="Fecha" />
              </div>
              <div className="file-field input-field">
                  <div className="btn btn-login">
                    <span>Cargar fotografía</span>
                    <input type="file" onChange={this.handleChargeFile} accept="image/x-png,image/gif,image/jpeg"/>
                  </div>
                  <div className="file-path-wrapper">
                    <input className="file-path validate" readOnly type="text"  />
                    <span>Nombre del Archivo:</span>
                  </div>
              </div>
              <div className="input-field center">
                <button className="btn btn-login">Crear</button>
              </div>
            </form>
            </div>
            </div>
          </div>
          <Footer/>
      </div>
    )
  }
}
 
const mapStateToProps = (state, ownProps) => {
const idcliente =  ownProps.match.params.id
  return {
    auth: state.firebase.auth,
    idcliente: idcliente    
  }
}
 
const mapDispatchToProps = dispatch => {
  return {
    createProject: (project) => dispatch(createProject(project))
  }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(CreateProject)
 

