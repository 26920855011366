import React, { Component } from 'react'
import ProjectList from '../projects/ProjectList'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Navbar from '../../components/layout/Navbar'
import Footer from '../../components/layout/Footer'
import { Link } from 'react-router-dom'
import { Icon } from 'react-materialize'
import { Button } from 'react-materialize'


class DashboardProjects extends Component {

  state = {
    textBuscar: ''
  }


  filter(event){
    this.setState({
      textBuscar: event.target.value
    })
  }
 
  render() {
   
    const { auth, idCliente, nameClient} = this.props;

    if (!auth.uid || (auth.uid !=="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/admin' /> 

      return (
        <div className="adminBody">
          <Navbar/>
          <div className="dashboard container">
            <div className="row">
              <div className="col s8 m8 offset-m1 add-icon">
                <i class="fas fa-city"></i>
                <h4 className="clients">{nameClient + ': PROYECTOS'}</h4>
              </div>
              <div className="col s3 col m2 add-icon right">
                  <Link to={'/crearproyecto/' + idCliente} key={idCliente}>
                    <Button
                      floating
                      large
                      className="plus_client"
                      waves="light"
                      icon={  <Icon>
                        add
                        </Icon>}
                    />
                  </Link>
              </div>
            </div>
            <div className="row div_ul">
              <input className="form-control"  placeholder="Buscar..." value={this.state.text} onChange={(text) => this.filter(text)}/> 
            </div> 
            <div className="">
              <div className="row div_ul">
                <ProjectList props={this.props} textBuscar={this.state.textBuscar}/>
              </div>
            </div>
          </div>
          <Footer/>
        </div>    
      )
  }
}

const mapStateToProps = (state, ownProps) => {

  const idcliente =  ownProps.match.params.id;
  const clients = state.firestore.ordered.clientsAdmin;
  var nameClient = null;
  if(clients){ 
    for(var i=0; i<clients.length; i++){
        if(clients[i].id === idcliente){
          nameClient = clients[i].nameClient
        }
      }
  }
  return {
    projects: state.firestore.ordered.projects,
    auth: state.firebase.auth,
    idCliente: idcliente,
    nameClient: nameClient
  }
  
}
const clientsAdmin = 'clientsAdmin'
export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    { collection: 'projects', 
    where: [
      ['idCliente', '==', props.idCliente]
    ]
    },
    { collection: 'clients', 
      doc: props.idCliente,
      storeAs: clientsAdmin
    }
  ])
)(DashboardProjects)
