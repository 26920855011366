import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import Navbar from '../../components/layout/Navbar'
import Footer from '../../components/layout/Footer'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { updateProjects } from '../../store/actions/userActions'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';



class EditUser extends Component {
    state = {
      uid: this.props.user.uid,
      idCliente: this.props.idCliente,
      checked: {}
  }

  handleChange = (e) => {
    e.persist();
    const isChecked = e.target.checked;
    this.setState(
        ({checked}) => ({
            checked: {
                ...checked,
                [e.target.id]: isChecked,
            },
            [e.target.id] : e.target.checked
        }),
        
    )
   
  }
  

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.updateProjects(this.state);
    this.props.history.push('/usuarios/' + this.state.idCliente);
}


  render() {
    const { auth, user } = this.props;
   
    if (!auth.uid || (auth.uid !=="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/admin' />  
    return (
      <div className="adminBody">
         <Navbar/>
         <div className="container">
         <div className="row">
            <div className="col s12 m10 offset-m1 add-icon">
              <i class="fas fa-user-circle"></i>
              <h5 className="title-projects titleProyAsign">Proyectos asignados a: {user.name}</h5>
            <form className="white" onSubmit={this.handleSubmit}> 
                { user.idproyects && Object.values(user.idproyects).map(function(item){
                  var initialState = false
                  if(this.state.checked[item.nombre] !== undefined){
                    initialState = true
                  } else {
                    initialState = false
                  }
                        return (
                            <div className="row"  key={item.idProject + '-' + item.nombre}>
                                    <p>
                                    <FormControlLabel
                                        control={
                                          <Checkbox
                                            id={item.nombre}
                                            checked={initialState ? !!this.state.checked[item.nombre] : item.status }
                                            onChange={this.handleChange}
                                            color="primary"
                                          />
                                        }
                                        label={item.nombre}
                                      />
                                    </p>
                            </div>
                        ) 
                }, this)}
                <div className="input-field center">
                <button className="btn btn-login">Actualizar</button>
              </div>
            </form>
            </div>
            </div>
          </div>
         <Footer/>
      </div>

    )
  }

}

const mapStateToProps = (state, ownProps) => {
  const idCP = ownProps.match.params.id;
  var res = idCP.split("&");
  const id = res[1];
  const idCliente = res[0]
  const users = state.firestore.data.users;
  const user = users ? users[id] : null;
  return {
    id : id,
    user: user,
    auth: state.firebase.auth,
    idCliente: idCliente
  }
}

const mapDispatchToProps = dispatch => {
  return {
      updateProjects: (userProject) => dispatch(updateProjects(userProject))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [
    { collection: 'users', 
      doc: props.id,
      subcollections: [{ collection: 'idproyects' }]
    },
    { collection: 'users', 
      where: [
        ['idCliente', '==', props.idCliente]
      ]
    },
    { collection: 'projects', 
    where: [
      ['idCliente', '==', props.idCliente]
    ]
  }
  ])
)(EditUser)




