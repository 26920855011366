import React from 'react'
import PilotoGallery from './PilotoGallery';
import { Tabs, Tab, Collection, CollectionItem, Icon } from 'react-materialize';
import ReactPlayer from 'react-player';
import FooterUser from '../layout/FooterUser'



const TabsPiloto = (pilotos) => {


    
    if(pilotos.pilotos[0].gallery === "yes" & pilotos.pilotos[0].urlVideos.length === 0 & pilotos.pilotos[0].urlRecorridos.length ===0){
        return (
            <>
                <Tabs className="center tabs_proyects" scope="tabs-24" style={{ marginTop: 50 }}>
                    <Tab
                        active
                        options={{
                            duration: 300,
                            onShow: null,
                            responsiveThreshold: Infinity,
                            swipeable: false
                        }}
                        title="Fotografías"
                    >
                        <PilotoGallery pilotos={pilotos} />
                    </Tab>
                </Tabs>
            </>

        )
    }
    else if(pilotos.pilotos[0].gallery === "yes" & pilotos.pilotos[0].urlVideos.length !==0 & pilotos.pilotos[0].urlRecorridos.length === 0){
        return (
            <>
                <Tabs className="center tabs_proyects" scope="tabs-24" style={{ marginTop: 50 }}>
                    <Tab
                        active
                        options={{
                            duration: 300,
                            onShow: null,
                            responsiveThreshold: Infinity,
                            swipeable: false
                        }}
                        title="Fotografías"
                    >
                        <PilotoGallery pilotos={pilotos} />
                    </Tab>
                    <Tab
                        options={{
                            duration: 300,
                            onShow: null,
                            responsiveThreshold: Infinity,
                            swipeable: false
                        }}
                        title="Videos"
                    >
                        {pilotos.pilotos[0].urlVideos.map((urlVideo, index) => {
                            return (
                                <div className="col s12 m4" key={index}>
                                    <ReactPlayer
                                        url={urlVideo}
                                        className='react-player'
                                        controls
                                        width='100%'
                                        height='100%'
                                    />
                                    <div className="box_download_album">
                                        <a className="download_album" href={"javascript:window.open('" + urlVideo + "','','width=600,height=400,left=50,top=50,toolbar=yes');void 0"}>
                                            <Icon style={{ marginLeft: 0 }}>aspect_ratio</Icon> ABRIR EN UNA NUEVA VENTANA
                                        </a>
                                    </div>
                                </div>
                            )
                        })}
                    </Tab>
                </Tabs>
            </>

        )
    }
    else if(pilotos.pilotos[0].gallery === "yes" & pilotos.pilotos[0].urlVideos.length !==0 & pilotos.pilotos[0].urlRecorridos.length !==0){
        return (
            <>
                <Tabs className="center tabs_proyects" scope="tabs-24" style={{ marginTop: 50 }}>
                    <Tab
                        active
                        options={{
                            duration: 300,
                            onShow: null,
                            responsiveThreshold: Infinity,
                            swipeable: false
                        }}
                        title="Fotografías"
                    >
                        <PilotoGallery pilotos={pilotos} />
                    </Tab>
                    <Tab
                        options={{
                            duration: 300,
                            onShow: null,
                            responsiveThreshold: Infinity,
                            swipeable: false
                        }}
                        title="Videos"
                    >
                        {pilotos.pilotos[0].urlVideos.map((urlVideo, index) => {
                            return (
                                <div className="col s12 m4" key={index}>
                                    <ReactPlayer
                                        url={urlVideo}
                                        className='react-player'
                                        controls
                                        width='100%'
                                        height='100%'
                                    /> 
                                    <div className="box_download_album">
                                        <a className="download_album" href={"javascript:window.open('" + urlVideo + "','','width=600,height=400,left=50,top=50,toolbar=yes');void 0"}>
                                            <Icon style={{ marginLeft: 0 }}>aspect_ratio</Icon> ABRIR EN UNA NUEVA VENTANA
                                        </a>
                                    </div>
                                </div>
                            )
                        })}
                    </Tab>
                    <Tab
                        options={{
                            duration: 300,
                            onShow: null,
                            responsiveThreshold: Infinity,
                            swipeable: false
                        }}
                        title="Recorridos 360°"
                    >
                        {pilotos.pilotos[0].urlRecorridos.map((urlRecorrido, index) => {
                            return (
                                <div className="col s12 m4" key={index}>
                                    <iframe src={urlRecorrido} frameborder="0"
                                        allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" allow="acelerómetro; reproducción automática; escritura en portapapeles; medios cifrados; giroscopio; imagen en- imagen completa" />
                                     <div className="box_download_album">
                                        <a className="download_album" href={"javascript:window.open('" + urlRecorrido + "','','width=600,height=400,left=50,top=50,toolbar=yes');void 0"}>
                                            <Icon style={{ marginLeft: 0 }}>aspect_ratio</Icon> ABRIR EN UNA NUEVA VENTANA
                                        </a>
                                    </div>                                
                                </div>
                            )
                        })}
                    </Tab>
                </Tabs>
            </>

        )
    }
    else if(pilotos.pilotos[0].gallery === "yes" & pilotos.pilotos[0].urlVideos.length ===0 & pilotos.pilotos[0].urlRecorridos.length !==0){
        return (
            <>
                <Tabs className="center tabs_proyects" scope="tabs-24" style={{ marginTop: 50 }}>
                    <Tab
                        active
                        options={{
                            duration: 300,
                            onShow: null,
                            responsiveThreshold: Infinity,
                            swipeable: false
                        }}
                        title="Fotografías"
                    >
                        <PilotoGallery pilotos={pilotos} />
                    </Tab>
                    <Tab
                        options={{
                            duration: 300,
                            onShow: null,
                            responsiveThreshold: Infinity,
                            swipeable: false
                        }}
                        title="Recorridos 360°"
                    >
                        {pilotos.pilotos[0].urlRecorridos.map((urlRecorrido, index) => {
                            return (
                                <div className="col s12 m4" key={index}>
                                    <iframe src={urlRecorrido} frameborder="0"
                                        allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" allow="acelerómetro; reproducción automática; escritura en portapapeles; medios cifrados; giroscopio; imagen en- imagen completa" />
                                    <div className="box_download_album">
                                        <a className="download_album" href={"javascript:window.open('" + urlRecorrido + "','','width=600,height=400,left=50,top=50,toolbar=yes');void 0"}>
                                            <Icon style={{ marginLeft: 0 }}>aspect_ratio</Icon> ABRIR EN UNA NUEVA VENTANA
                                        </a>
                                    </div>
                                </div>
                            )
                        })}
                    </Tab>
                </Tabs>
            </>

        )
    }
    else if(pilotos.pilotos[0].gallery !== "yes" & pilotos.pilotos[0].urlVideos.length !==0 & pilotos.pilotos[0].urlRecorridos.length !==0 ){
        return (
            <>
                <Tabs className="center tabs_proyects" scope="tabs-24" style={{ marginTop: 50 }}>
                    <Tab
                        options={{
                            duration: 300,
                            onShow: null,
                            responsiveThreshold: Infinity,
                            swipeable: false
                        }}
                        title="Videos"
                    >
                        {pilotos.pilotos[0].urlVideos.map((urlVideo, index) => {
                            return (
                                <div className="col s12 m4" key={index}>
                                    <ReactPlayer
                                        url={urlVideo}
                                        className='react-player'
                                        controls
                                        width='100%'
                                        height='100%'
                                    />
                                    <div className="box_download_album">
                                        <a className="download_album" href={"javascript:window.open('" + urlVideo + "','','width=600,height=400,left=50,top=50,toolbar=yes');void 0"}>
                                            <Icon style={{ marginLeft: 0 }}>aspect_ratio</Icon> ABRIR EN UNA NUEVA VENTANA
                                        </a>
                                    </div>
                                </div>
                            )
                        })}
                    </Tab>
                    <Tab
                        options={{
                            duration: 300,
                            onShow: null,
                            responsiveThreshold: Infinity,
                            swipeable: false
                        }}
                        title="Recorridos 360°"
                    >
                        {pilotos.pilotos[0].urlRecorridos.map((urlRecorrido, index) => {
                            return (
                                <div className="col s12 m4" key={index}>
                                    <iframe src={urlRecorrido} frameborder="0"
                                        allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" allow="acelerómetro; reproducción automática; escritura en portapapeles; medios cifrados; giroscopio; imagen en- imagen completa" />
                                    <div className="box_download_album">
                                        <a className="download_album" href={"javascript:window.open('" + urlRecorrido + "','','width=600,height=400,left=50,top=50,toolbar=yes');void 0"}>
                                            <Icon style={{ marginLeft: 0 }}>aspect_ratio</Icon> ABRIR EN UNA NUEVA VENTANA
                                        </a>
                                    </div>
                                </div>
                            )
                        })}
                    </Tab>
                </Tabs>
            </>

        )
    }
    else if(pilotos.pilotos[0].gallery !== "yes" & pilotos.pilotos[0].urlVideos.length !==0 & pilotos.pilotos[0].urlRecorridos.length ===0 ){
        return (
            <>
                <Tabs className="center tabs_proyects" scope="tabs-24" style={{ marginTop: 50 }}>
                    <Tab
                        options={{
                            duration: 300,
                            onShow: null,
                            responsiveThreshold: Infinity,
                            swipeable: false
                        }}
                        title="Videos"
                    >
                        {pilotos.pilotos[0].urlVideos.map((urlVideo, index) => {
                            return (
                                <div className="col s12 m4" key={index}>
                                    <ReactPlayer
                                        url={urlVideo}
                                        className='react-player'
                                        controls
                                        width='100%'
                                        height='100%'
                                    />
                                    <div className="box_download_album">
                                        <a className="download_album" href={"javascript:window.open('" + urlVideo + "','','width=600,height=400,left=50,top=50,toolbar=yes');void 0"}>
                                            <Icon style={{ marginLeft: 0 }}>aspect_ratio</Icon> ABRIR EN UNA NUEVA VENTANA
                                        </a>
                                    </div>
                                </div>
                            )
                        })}
                    </Tab>
                </Tabs>
            </>

        )
    }
    else if(pilotos.pilotos[0].gallery !== "yes" & pilotos.pilotos[0].urlVideos.length ===0 & pilotos.pilotos[0].urlRecorridos.length !==0 ){
        return (
            <>
                <Tabs className="center tabs_proyects" scope="tabs-24" style={{ marginTop: 50 }}>
                    <Tab
                            options={{
                                duration: 300,
                                onShow: null,
                                responsiveThreshold: Infinity,
                                swipeable: false
                            }}
                            title="Recorridos 360°"
                        >
                            {pilotos.pilotos[0].urlRecorridos.map((urlRecorrido, index) => {
                                return (
                                    <div className="col s12 m4" key={index}>
                                        <iframe src={urlRecorrido} frameborder="0"
                                            allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" allow="acelerómetro; reproducción automática; escritura en portapapeles; medios cifrados; giroscopio; imagen en- imagen completa" />
                                        <div className="box_download_album">
                                            <a className="download_album" href={"javascript:window.open('" + urlRecorrido + "','','width=600,height=400,left=50,top=50,toolbar=yes');void 0"}>
                                                <Icon style={{ marginLeft: 0 }}>aspect_ratio</Icon> ABRIR EN UNA NUEVA VENTANA
                                            </a>
                                        </div>
                                    </div>
                                )
                            })}
                        </Tab>
                </Tabs>
            </>

        )
    }
   
}



export default TabsPiloto