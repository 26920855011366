import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { createPiloto } from '../../store/actions/pilotoActions'
import { Redirect } from 'react-router-dom'
import Navbar from '../../components/layout/Navbar'
import Footer from '../../components/layout/Footer'
import "react-datepicker/dist/react-datepicker.css";
import Resizer from 'react-image-file-resizer';
import { BallBeat } from 'react-pure-loaders';
import { clean } from '../../store/actions/pilotoActions'
import CreateVideo from './CreateVideo'
import CreateRecorrido from './CreateRecorrido'
import moment from 'moment';


class CreatePiloto extends Component {

    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        load: "Cargar fotos galería",
        loadPiloto: "Crear",
        disabled: false,
        name: '',
        idProject: this.props.idProject,
        gallery_photos:'',
        gallery: '',
        zip: '',
        urlVideos: [],
        urlRecorridos: [],
        loadingPiloto: false,
        classBtn: "btn disabled",
        classBtnZip: "btn btn-login",
        currentDateTime:"",
        editionDateTime:'',
        clientID: ""
      }
    }
  

  
  handleChangeName = (e) => {
      this.setState({
        [e.target.id]: e.target.value,
        currentDateTime: moment(Date().toLocaleString()).format("DD-MM-YYYY"),
        clientID: this.props.project[0].idCliente
      })

  }

  
  handleChargeZip = (e) => {
    e.preventDefault();
    this.setState({
      zip: e.target.files[0],
      classBtn: "btn btn-login",
      loadPiloto: "CREAR" 
    });
  }

  handleChargeFileMultiple = (e) => {
    e.preventDefault();
    this.setState({ 
      loading : true,
      load: "Comprimiendo galería, espere un momento...", 
      classBtnZip: "btn disabled",
      disabled: !this.state.disabled,
      classBtn: "btn disabled",
    })

    var filePilotoGallery=[];
    for(var i=0; i < e.target.files.length; i++){
        Resizer.imageFileResizer(   
          e.target.files[i],
          1000,
          1000,
          'PNG',
          90,
          0,
          uri => {
            filePilotoGallery.push(uri);
              if(filePilotoGallery.length === i){
                this.setState({ 
                  load: "Galería comprimida",
                  loading: false,
                  classBtn: "btn btn-login",
                  classBtnZip: "btn btn-login",
                  disabled: false,
                  gallery: "yes",
                  loadPiloto: "CREAR" 
                });
              }
          },
          'blob'
          
      );
    }
    this.setState({ 
      gallery_photos: filePilotoGallery
    });
  }
  handleVideoUrl = (urlVideos) => {
    this.setState({
      urlVideos: urlVideos,
      classBtn: "btn btn-login",
      loadPiloto: "CREAR" 
    })
  }

  handleRecorridoUrl = (urlRecorridos) => {
    this.setState({
      urlRecorridos: urlRecorridos,
      classBtn: "btn btn-login",
      loadPiloto: "CREAR" 
    })
  }



  handleSubmit = (e) => {
    e.preventDefault();
 
     this.props.createPiloto(this.state);
      this.setState({ 
        loadPiloto: "Creando piloto, no cierre esta ventana hasta que se complete la acción",
        loadingPiloto: true,
        classBtn: "btn disabled",
        classBtnZip: "btn disabled"
      });
 
 
    if( this.state.gallery_photos === '' ){
      this.props.history.push('/proyectos-detalle/'+ this.props.idProject)
    }
  }


componentDidUpdate(){
  if(this.state.zip === '' && this.props.successGallery_p !== null){
      this.props.history.push('/proyectos-detalle/'+ this.props.idProject)
      this.props.clean();
  } if (this.state.zip !== '' && this.props.successGallery_p !== null && this.props.successZip !== null) {
      this.props.history.push('/proyectos-detalle/'+ this.props.idProject)
      this.props.clean();
  }
}


  render() {
    const { auth, project } = this.props;
    if (!auth.uid || (auth.uid !=="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/admin' /> 
    return (
      <div className="adminBody">
         <Navbar/>
          <div className="container">
          <div className="row">
            <div className="col s12 m10 offset-m1 add-icon">
            <form className="white" onSubmit={this.handleSubmit}>
              <i class="fas fa-file"></i>
              <h5 className="titleFormCreate">Crear un nuevo piloto</h5>
              <div className="input-field row">
                <input type="text" id='name' onChange={this.handleChangeName} required/>
                <label htmlFor="name">Nombre</label>
              </div>
              <div className="input-field row">
                <CreateVideo props={this.handleVideoUrl}/>
              </div>
              <div className="input-field row">
                <CreateRecorrido props={this.handleRecorridoUrl}/>
              </div>
    
              <div className="file-field input-field box_change_downloader">
                  <div className={this.state.classBtnZip}>
                    <span>{this.state.load}</span>
                    <input type="file" multiple accept="image/x-png,image/gif,image/jpeg" onChange={this.handleChargeFileMultiple}/>
                  </div>
                  <div className="file-path-wrapper">
                    <input  placeholder="Carga uno o más archivos" readOnly className="file-path validate" type="text" />
                    <span>Nombre del/los  Archivo:</span>
                  </div>
                  <div className="center">
                    <BallBeat
                        color={'#123abc'}
                        loading={this.state.loading}
                      />
                  </div>
              </div>
              <div className="file-field input-field box_change_downloader">
                  <div className={this.state.classBtnZip}>
                    <span>Cargar descargable galería</span>
                    <input type="file" accept="application/x-zip-compressed" onChange={this.handleChargeZip}/>
                  </div>
                  <div className="file-path-wrapper">
                    <input placeholder="Carga un archivo zip" readOnly className="file-path validate" type="text" />
                    <span>Nombre del Archivo:</span>
                  </div>
              </div>
              <div className="input-field center">
                <button className={this.state.classBtn}>{this.state.loadPiloto}</button>
              </div>
              <div className="center">
                    <BallBeat
                        color={'#123abc'}
                        loading={this.state.loadingPiloto}
                      />
              </div>
            </form>
            </div>
            </div>
          </div>
          <Footer/>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
console.log(state)
  const idproject =  ownProps.match.params.id
  const project =  state.firestore.ordered.projects;
  

  return {
    auth: state.firebase.auth,
    idProject: idproject,
    successGallery_p: state.piloto.successGallery_p,
    successZip: state.piloto.successZip,
    project: project
  }
  
}

const mapDispatchToProps = dispatch => {
  return {
    createPiloto: (piloto) => dispatch(createPiloto(piloto)),
    clean: () => dispatch(clean())
  }
}
// export default compose(
//   connect(mapStateToProps, mapDispatchToProps),
//   firestoreConnect((props) => [
//     { collection: 'projects',
//       doc: props.idProject
//     }
//   ])
// )(CreatePiloto)

export default connect(mapStateToProps, mapDispatchToProps)(CreatePiloto)
