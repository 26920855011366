import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { Button } from 'react-materialize'
import NavbarUser from '../../components/layout/NavbarUser'
import FooterUser from '../../components/layout/FooterUser'
import { firestoreConnect } from 'react-redux-firebase'



class ReportsUserClose extends Component {
    render() {
      const { auth, projectsUser, projectsId } = this.props;
      if (!auth.uid || (auth.uid ==="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/' /> 
      let projectsUserFilter = [];
      let sortedActivities = null
      if (projectsId && projectsUser){
            for(var i=0; i<projectsId.length;i++){
                for(var j=0; j<projectsUser.length;j++){
                    if(projectsId[i].idProject === projectsUser[j].id){
                        projectsUserFilter.push(projectsUser[j]);
                    }
                }
            }
            if(projectsUserFilter.length !== 0){
                sortedActivities = projectsUserFilter.sort((a, b) => b.date.seconds - a.date.seconds)
            }
            return (
                <div className="userBody">
                    <NavbarUser/>
                    <div className="titleBody">
                        <div className="row">
                                <div className="col s8 m8 offset-m1">
                                    <div className="textTitleBox">
                                        <i class="fas fa-building"></i>
                                        <h4>PROYECTOS CERRADOS</h4>
                                    </div>
                                </div>
                        </div>
                        <div className="row div_ul">
                            {sortedActivities.map(project => {
                                if(project.value ==="CERRADO"){
                                    return (
                                        <div className="col s12 m4" >
                                            <Link  to= {'/entregable/' + project.id} className="containerImg">
                                                <div className="boxImgClient">
                                                    <img src={project.img_project} className="proyectImg" alt="imagen de proyecto"/>
                                                    <div className="capaBoxImgClient"></div>
                                                    <div className="textImgClient">
                                                    <h5>{project.name}</h5> 
                                                    <p>{project.place}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                }
                            })}  
                        </div>
                        <div className="row boxBtn">
                            <Link to={'entregables'} >
                                <div className="">
                                    <Button className="btnClient">
                                    Ver Proyectos Actuales
                                    </Button>
                                </div>
                            </Link>
                        </div> 
                    </div>
                    <FooterUser/>
                </div>
            )
        } else {
            return(
                <div className="userBody">
                    <NavbarUser/>
                    <div className="mensajeNo">Loading...</div>
                    <FooterUser/>
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    const user = state.firestore.ordered.users;
    const idUser = user ? user[0].id : null
    const client = state.firestore.ordered.cliente
    const idclient = client ? client[0].id : null;
    const projectsId = state.firestore.ordered.userProjects;
    const projectsUser = state.firestore.ordered.projects;
    return{
        auth: state.firebase.auth,
        id :idUser,
        idClient : idclient,
        projectsId: projectsId,
        projectsUser: projectsUser
    }
}
const userProjects = 'userProjects'
export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) =>[
    { collection: 'projects', 
      where: [
        ['idCliente', '==', props.idClient ? props.idClient : null]
      ]
    },
    { collection: 'users', 
    doc: props.id ? props.id : 'ljsdlahshfs',
    subcollections: [{ collection: 'idproyects' }],
    where:[
        ['status', '==', true]
    ],
    storeAs: userProjects,
  }
    ])
  )(ReportsUserClose)

// const mapStateToProps = (state) => {
//     const projectsId = state.firestore.ordered.userProjects;
//     const projectsUser = state.firestore.ordered.projects;
//     return{
//         auth: state.firebase.auth,
//         projectsId: projectsId,
//         projectsUser: projectsUser
//     }
// }

// export default compose(
//     connect(mapStateToProps)
//   )(ProjectsUserClose)

