import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signInAdmin } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'
import Modal from '../modal/Modal'

class clientes extends Component {
  state = {
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signInAdmin(this.state)
  }
  render() {
    const { authError, auth } = this.props;
    if (auth.uid && (auth.uid !=="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/proyectos' /> 
    if(auth.uid ==="iAc06DPtCDM0Z7eXF1trMMjHGaz2") return  <Redirect to='/dashboard' /> 
    return (
      <div className="loginContainer">
        <div className="container">
          <div className="brand_logo_container center">
            <img src="./../img/logo.png" className="brand_logo" alt="Logo"/>
          </div>
          <div className="valign-wrapper">
              <div className="card hoverable">
                <form className="loginAccess" onSubmit={this.handleSubmit}>
                  <h5 className="center title_access">ACCESO CLIENTES</h5>
                  <div className="input-field icon_user">
                  <i className="fas fa-portrait"></i>
                    <label htmlFor="email_admin">USUARIO</label>
                    <input type="email" id='email_admin' onChange={this.handleChange} />
                  </div>
                  <div className="input-field icon_pass">
                  <i className="fas fa-lock"></i>
                    <label htmlFor="password_admin">CONTRASEÑA</label>
                    <input type="password" id='password_admin' onChange={this.handleChange} />
                  </div>
                  <div className="input-field center">
                    <button className="btn btn-login">INGRESAR</button>
                    <div className="center red-text">
                      { authError ? <p>{authError}</p> : null }
                    </div>
                  </div>
                </form>
                <div className="center-align">        
                  <Modal />
                </div>
              </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return{
    authError: state.auth.authError,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signInAdmin: (creds) => dispatch(signInAdmin(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(clientes)