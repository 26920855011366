
export const sendEmail= (email) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore();
      firestore.collection('contacts').add({
        user: email.fname,
        subject: email.message,
        from: email.email
      }).then(() => {
        dispatch({ type: 'CREATE_CONTACT_SUCCESS' }); 
      }).catch(err => {
        dispatch({ type: 'CREATE_CONTACT_ERROR' }, err);
      });
    }
  };

  export const close= () => {
    return (dispatch) => {
        dispatch({ type: 'CREATE_CONTACT_SUCCES_CLOSE' }); 
    }
  };