import React, { useState, useEffect  } from 'react'
import { Button } from 'react-materialize'
import { Icon } from 'react-materialize'


const CreateVideo =({props}) => {

 const  handleVideoUrl  = props

  const [urlVideos, setUrlVideos] = useState([]);  
  

  const addInputButton = (e) => {
      e.preventDefault()
      setUrlVideos([ ... urlVideos, ""])
    
  }

  const handleChangeVideo = (e, index) => {
     urlVideos[index] = e.target.value;
     setUrlVideos([ ... urlVideos]);
  }

  const handleDeleteInputVideo = (position) =>{
      setUrlVideos((urlVideos) => urlVideos.filter((_, index) => index !== position));
  }


    useEffect(() => {
        handleVideoUrl(urlVideos);
    }, [urlVideos]); 


  return (

      <div>
          <div className="add_item">
            <Button 
                onClick={addInputButton}
                floating
                large
                className="plus_client"
                waves="light"
                icon={  <Icon>
                            add
                        </Icon>}
            />
            <label>Añadir Url de Videos</label>
         </div>
            {urlVideos.map((urlVideo, index) =>(
                <div key={index} style={{display:'flex'}}>
                    <input className="input_edit_admin" type="text" name="urlVideo" value={urlVideo} id={`urlVideo-${index+1}`} placeholder={`urlVideo-${index+1}`} onChange={(e)=> handleChangeVideo(e, index)}/>
                    {/* <label htmlFor="urlVideo"></label> */}
                    <Button className="btn_delete_admin"
                        onClick={() => {handleDeleteInputVideo(index)}}
                        icon={  <Icon>
                                  delete
                                </Icon>}
                    />
                </div>
  
            ))}
           
          
      </div>
     
  )
  

}

export default CreateVideo