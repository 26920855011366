import authReducer from './authReducer'
import projectReducer from './projectReducer'
import clientReducer from './clientReducer';
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase'
import emailReducer from './emailReducer';
import reportReducer from './reportReducer';
import pilotoReducer from './pilotoReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  project: projectReducer,
  client: clientReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  email: emailReducer,
  report: reportReducer,
  piloto: pilotoReducer
});

export default rootReducer

// the key name will be the data property on the state object