const initState = {  
    contactError: null,
    successContact: null
  }
  
  const emailReducer = (state = initState, action) => {
    switch(action.type){
        case 'CREATE_CONTACT_SUCCESS':
        return {
          ...state,
          successContact: "Gracias por tu mensaje, te responderemos a la brevedad",
          contactError: null
        }
    
      case 'CREATE_CONTACT_ERROR':
        return {
          ...state,
          successContact: null,
          contactError: action.err.message
        }
       case 'CREATE_CONTACT_SUCCES_CLOSE': 
       return{
        ...state,
        successContact: null,
        contactError: null
       }
  
      default:
        return state
    }
  };
  
  export default emailReducer;