import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Navbar from '../layout/Navbar'
import Footer from '../layout/Footer'
import { editPiloto } from '../../store/actions/pilotoActions'
import "react-datepicker/dist/react-datepicker.css";
import Resizer from 'react-image-file-resizer';
import { BallBeat } from 'react-pure-loaders';
import { clean } from '../../store/actions/pilotoActions'
import { Button } from 'react-materialize'
import EditVideo from './EditVideo'
import EditRecorrido from './EditRecorrido'
import ModalDeletePiloto from '../modal/ModalDeletePiloto'
import moment from 'moment';

class EditPiloto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      load: "Cambiar galería",
      disabled: false,
      name: '',
      idproject: this.props.idproject,
      gallery_photos:'',
      gallery:'',
      zip: '',
      urlVideos: [],
      urlRecorridos: [],
      loadingPiloto: false,
      classBtn: "btn disabled",
      classBtnZip: "btn btn-login", 
      id: this.props.id,
      editionDateTime: ''
    }
  }



handleChangeName = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
}


handleChargeZip = (e) => {
  e.preventDefault();
  this.setState({ zip: e.target.files[0] });
}

handleChargeFileMultiple = (e) => {
  e.preventDefault();
  this.setState({ 
    loading : true,
    load: "Comprimiendo galería, espere un momento...", 
    classBtnZip: "btn disabled",
    disabled: !this.state.disabled,
    classBtn: "btn disabled",
  })

  var filePilotoGallery=[];
  for(var i=0; i < e.target.files.length; i++){
      Resizer.imageFileResizer(   
        e.target.files[i],
        1000,
        1000,
        'PNG',
        90,
        0,
        uri => {
          filePilotoGallery.push(uri);
            if(filePilotoGallery.length === i){
              this.setState({ 
                load: "Galería comprimida",
                loading: false,
                classBtn: "btn btn-login",
                classBtnZip: "btn btn-login",
                disabled: false,
                gallery: "yes",
                loadPiloto: "guardar cambios" 
              });
            }
        },
        'blob'
        
    );
  }
  this.setState({ 
    gallery_photos: filePilotoGallery
  });
}
handleVideoUrl = (urlVideos) => {
  this.setState({
    urlVideos: urlVideos,
    classBtn: "btn btn-login",
    disabled: false,
    loadPiloto: "guardar cambios",
    id: this.props.id,
    editionDateTime: moment(Date().toLocaleString()).format("DD-MM-YYYY")
  })
}

handleRecorridoUrl = (urlRecorridos) => {
  // console.log(urlRecorridos)
  this.setState({
    urlRecorridos: urlRecorridos,
    classBtn: "btn btn-login",
    disabled: false,
    loadPiloto: "guardar cambios" 
  })
}

// handleDeletePiloto=(backOptionsModal) =>{
//   this.setState({
//     backOptions: backOptionsModal
//   })
// }



handleSubmit = (e) => {
  e.preventDefault();
  this.props.editPiloto(this.state);
    this.setState({ 
      loadPiloto: "Editando piloto, no cierre esta ventana hasta que se complete la acción",
      loadingPiloto: true,
      classBtn: "btn disabled",
      classBtnZip: "btn disabled"
    });
  if( this.state.gallery_photos === '' ){
    this.props.history.push('/proyectos-detalle/'+ this.props.idproject,)
  }
}


componentDidUpdate(){
  if(this.state.zip === '' && this.props.successGallery_p !== null){
      this.props.history.push('/proyectos-detalle/'+ this.props.idproject)
      this.props.clean();
  } if (this.state.zip !== '' && this.props.successGallery_p !== null && this.props.successZip !== null) {
      this.props.history.push('/proyectos-detalle/'+ this.props.idproject)
      this.props.clean();
  } 
  if(this.props.deletePilotoTrue !== null){
    this.props.history.push('/proyectos-detalle/'+ this.props.idproject)
    this.props.clean();
   
  }

}
  
  render() {
   
    const { auth, nameProject, pilotos, id} = this.props;
    if (!auth.uid || (auth.uid !=="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/admin' /> 
    if(pilotos){
      return (
        <div className="adminBody">
           <Navbar/>
            <div className="container">
            <div className="row">
              <div className="col s12 m10 offset-m1 add-icon admin_edit_pilot">
                <form className="white" onSubmit={this.handleSubmit}>
                  <i class="fas fa-file"></i>
                  <h5 className="titleFormCreate">EDITAR {pilotos[0].name}</h5>
                  <div className="input-field row">
                    <input type="text" id='name' onChange={this.handleChangeName}/>
                    <label htmlFor="name">{pilotos[0].name}</label>
                  </div>
                  <div className="input-field row">
                    <input disabled type="text" id='creationDate'/>
                    <label htmlFor="creationDate">Fecha de Creación: {pilotos[0].currentDateTime}</label>
                  </div>
                  <div className="input-field row">
                    <input disabled type="text" id='editionDate'/>
                    <label htmlFor="editionDate">Última edición: {pilotos[0].editionDateTime}</label>
                  </div>
                  <div className="input-field row">
                    <EditVideo props={this.handleVideoUrl} pilotos={pilotos[0].urlVideos}/>
                  </div>
                  <div className="input-field row">
                    <EditRecorrido props={this.handleRecorridoUrl} pilotos={pilotos[0].urlRecorridos}/>
                  </div>
                  <div className="file-field input-field box_change_downloader">
                      <div className={this.state.classBtnZip}>
                        <span>{this.state.load}</span>
                        <input type="file" multiple accept="image/x-png,image/gif,image/jpeg" onChange={this.handleChargeFileMultiple}/>
                      </div>
                      <div className="file-path-wrapper">
                        <input  placeholder="Carga uno o más archivos" readOnly className="file-path validate" type="text" />
                        <span>Nombre del/los  Archivo:</span>
                      </div>
                      <div className="center">
                        <BallBeat
                            color={'#123abc'}
                            loading={this.state.loading}
                          />
                      </div>
                  </div>
                  <div className="file-field input-field box_change_downloader">
                    <div className="btn btn-login">
                      <span>Cambiar descargable</span>
                      <input type="file" accept="application/x-zip-compressed" onChange={this.handleChargeZip}/>
                    </div>
                    <div className="file-path-wrapper">
                      <input placeholder="Carga un archivo zip" readOnly className="file-path validate" type="text" />
                      <span>Nombre del Archivo:</span>
                    </div>
                  </div>
                  <div className="input-field save_edit_admin">
                    <button className={this.state.classBtn}>{this.state.loadPiloto}</button>
                  </div>
                  <div className="center">
                        <BallBeat
                            color={'#123abc'}
                            loading={this.state.loadingPiloto}
                          />
                  </div>
                </form>
                {!this.state.loadingPiloto &&  <ModalDeletePiloto piloto={pilotos[0]}/>}
               
              </div>
              </div>
            </div>
            <Footer/>
        </div>
      )
    } 
   

  }

}

const mapStateToProps = (state, ownProps) => {
  console.log(state)
  const idCP = ownProps.match.params.id;
  var res = idCP.split("&");
  const id = res[1];
  const idproject = res[0]
  // const projects = state.firestore.data.projects;
  const pilotos = state.firestore.ordered.pilotos
  return {
    pilotos: pilotos,
    id: id,
    auth: state.firebase.auth,
    idproject: idproject,
    successGallery_p: state.piloto.successGallery_p,
    successZip: state.piloto.successZip,
    deletePilotoTrue: state.piloto.deletePilotoTrue
  }

  // const idPR = ownProps.match.params.id;
  // var res = idPR.split("&");
  // const idproject =  res[0];
  // const id = res[1];
  // const projects = state.firestore.ordered.projects;
  // const pilotos = state.firestore.ordered.pilotos
  // var nameProject = null;
  // if(projects){ 
  //   for(var i=0; i<projects.length; i++){
  //       if(projects[i].id === idproject){
  //         nameProject = projects[i].name
  //       }
  //     }
  // }

  // return {
  //   pilotos: pilotos,
  //   id: id,
  //   auth: state.firebase.auth,
  //   idproject: idproject,
  //   nameProject: nameProject,
  //   successGallery_p: state.piloto.successGallery_p,
  //   successZip: state.piloto.successZip,
  //   deletePilotoTrue: state.piloto.deletePilotoTrue
  // }

}
// const pilotos = 'pilotos';

const mapDispatchToProps = dispatch => {
  return {
    editPiloto: (piloto) => dispatch(editPiloto(piloto)),
    clean: () => dispatch(clean())
  }
}
// export default compose(
  export default  connect(mapStateToProps, mapDispatchToProps)(EditPiloto)
  // firestoreConnect((props) => [
  //   { collection: 'pilotos', 
  //   where: [
  //     ['idProject', '==', props.idproject]
  //   ],
  //   storeAs: 'pilotos'
  //   },
  //   { collection: 'projects',
  //     doc: props.idproject
  //   }
  // ])
// )(EditPiloto)
