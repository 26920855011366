import { storage } from "../../config/fbConfig";

export const createPiloto = (piloto) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    firestore.collection('pilotos').add({
      name: piloto.name,
      idProject: piloto.idProject,
      urlVideos: piloto.urlVideos,
      urlRecorridos: piloto.urlRecorridos,
      zip: "",
      gallery: piloto.gallery,
      currentDateTime: piloto.currentDateTime,
      editionDateTime: piloto.editionDateTime,
      clientID: piloto.clientID
    }).then((docId) => {
      // dispatch({ type: 'CREATE_PILOTO_SUCCESS' });
      gallery(piloto, docId.id, firestore, dispatch)
      pilotoItemProject(piloto, firestore, dispatch)
      if (piloto.zip!== '') {
       zip (piloto, docId.id, firestore, dispatch)
      }
    }).catch(err => {
      dispatch({ type: 'CREATE_PILOTO_ERROR' }, err);
    });
  }
};


//eliminar piloto
export const deletePiloto = (piloto) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    //eliminar el piloto, necesito el id del reporte
    firestore.collection('pilotos').doc(piloto.id).delete().then(() => {
        galleryDelete(piloto, piloto.id, firestore, dispatch)
      // dispatch({ type: 'DELETE_PILOTO' });
         //cambiar la existencia del piloto en el proyecto correspondiente
        firestore.collection('projects').doc(piloto.idProject).update({
          pilotoItem: "no"
        }).then(() => {
          dispatch({ type: 'DELETE_PILOTO' });
        }).catch(err => {
          dispatch({ type: 'DELETE_PILOTO_ERROR' }, err);
        });
    }).catch(err => {
      dispatch({ type: 'DELETE_PILOTO_ERROR' }, err);
    });
 
  }
};

const pilotoItemProject = (piloto, firestore, dispatch ) => {

  firestore.collection('projects').doc(piloto.idProject).update({
    pilotoItem: "yes"
  }).then(() => {
    dispatch({ type: 'CREATE_PILOTO_SUCCESS' });
  }).catch(err => {
    dispatch({ type: 'CREATE_PILOTO_ERROR' }, err);
  });
}

// gallery 
const gallery = (async (piloto, docId, firestore, dispatch) => {
  try {
    // var photo_counter = 0;
    const storageRef= storage.ref("photo_gallery_piloto/")
    for(var i=0; i<piloto.gallery_photos.length; i++){
      await storageRef.child(docId+ "/" + docId + i)
      .put(piloto.gallery_photos[i])
      //promesa 1
      .then((snapshot)=> {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          firestore.collection('pilotos').doc(docId).collection('photos_gallery').add({
            downloadURL
            // promesa 2
          }).then(()=>{
            if(i===(piloto.gallery_photos.length)){
              dispatch({type: 'SET_URL_PILOTO_GALLERY'})
            }
          }).catch(error => {
            console.error('error con el dispacth', error)
          })
        });
      }).catch(error => {
        console.error("error con firestore", error)
      })
    }
  } catch (error) {
    console.error('Error creando galeria', error);
  }
});


// zip 
const zip= (async(piloto, docId, firestore, dispatch) => {
  try {
    const storageRef= storage.ref("photo_gallery_piloto/")
    storageRef.child(docId + '.zip').put(piloto.zip).then((snapshot)=> {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        firestore.collection('pilotos').doc(docId).update({
          zip: downloadURL
        }).then(()=>{
          dispatch({type: 'SET_URL_ZIP'})
        }).catch(error => {
          console.error('error con el dispacth', error)
        })
      }).catch(error => {
        console.error('error con firestore', error)
      })
    }).catch(error => {
      console.error('error con storage', error)
    })
  }catch (error) {
    console.error('Error creando zip', error);
  }
})

// borrar galeria
const galleryDelete = (async (piloto, docId, firestore, dispatch) => {
  try {
    // borrar la galeria anterior
    firestore.collection('pilotos').doc(docId).collection('photos_gallery').get()
    .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          firestore.collection('pilotos').doc(docId).collection('photos_gallery').doc(doc.id).delete();
        });
    });

  } catch (error) {
    console.error('Error borrando galeria', error);
  }
});

//edit piloto
// gallery 
const galleryUpdate = (async (piloto, docId, firestore, dispatch) => {
  try {
    // borrar la galeria anterior
    firestore.collection('pilotos').doc(docId).collection('photos_gallery').get()
    .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          firestore.collection('pilotos').doc(docId).collection('photos_gallery').doc(doc.id).delete();
        });
    });

    const storageRef= storage.ref("photo_gallery_piloto/")
    for(var i=0; i<piloto.gallery_photos.length; i++){
      await storageRef.child(docId+ "/" + docId + i)
      .put(piloto.gallery_photos[i])
      //promesa 1
      .then((snapshot)=> {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          firestore.collection('pilotos').doc(docId).collection('photos_gallery').add({
            downloadURL
            // promesa 2
          }).then(()=>{
            if(i===(piloto.gallery_photos.length)){
              dispatch({type: 'SET_URL_PILOTO_GALLERY'})
            }
          }).catch(error => {
            console.error('error con el dispacth', error)
          })
        });
      }).catch(error => {
        console.error("error con firestore", error)
      })
    }
  } catch (error) {
    console.error('Error creando galeria', error);
  }
});

export const editPiloto = (piloto) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    if (piloto.name !== '') {
      firestore.collection('pilotos').doc(piloto.id).update({
        name: piloto.name
      }).then(() => {
        dispatch({ type: 'CREATE_PILOTO_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CREATE_PILOTO_ERROR' }, err);
      });  
    }
      firestore.collection('pilotos').doc(piloto.id).update({
        urlVideos: piloto.urlVideos,
        urlRecorridos: piloto.urlRecorridos,
        editionDateTime: piloto.editionDateTime
      }).then(() => {
        dispatch({ type: 'CREATE_PILOTO_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CREATE_PILOTO_ERROR' }, err);
      });  
    if (piloto.gallery_photos.length !== 0) {
      galleryUpdate(piloto, piloto.id, firestore, dispatch)
      firestore.collection('pilotos').doc(piloto.id).update({
        gallery: piloto.gallery
      }).then(() => {
        dispatch({ type: 'CREATE_PILOTO_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CREATE_PILOTO_ERROR' }, err);
      });  
    }
    if (piloto.zip.length !== 0) {
      zip(piloto, piloto.id, firestore, dispatch)
    }
  }
}


export const clean= () => {
  return (dispatch) => {
      dispatch({ type: 'SUCCESS_CLEAN' }); 
  }
};