import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
// import { signOut } from '../../store/actions/authActions'
// import { Icon, NavItem } from 'react-materialize'

const LinksNoUser = (props) => {
  const clienteID = props.cliente
  if(clienteID){
    return (
      <div className='interNav'>
        <NavLink to={'/pilotos/'+ clienteID[0].id} className="color_links">
          <i class="material-icons">camera_alt</i>PILOTOS
        </NavLink>
      </div>
    )
  } else {
    return (
      <div className='interNav'>
      </div>
    )
  }
}


  export default LinksNoUser
