import React from 'react'
import GalleryReport from './GalleryReport';
// import Image360Report from './360ImageReport';
import moment from 'moment';
import ReactPlayer from 'react-player'
import { Icon } from 'react-materialize';


const ReportView = (date)=> {
    let galeria;
    let tres60;
    let tresD;
    let video;
        if(date.date === null){
            var actualReport = [];
            var actualDate = 0;
            for(var i=0; i<date.reports.length; i++){
                if(date.reports[i].date.seconds > actualDate){
                    actualReport = date.reports[i];
                    actualDate = date.reports[i].date.seconds;
                }
            }            
            //opcionGaleria
            if(actualReport.gallery !==''){
                galeria = 
                    <div>
                        <div className="boxSectionBody">
                            <GalleryReport report={actualReport}/>
                        </div>
                    </div>
              
               
            }
            //opcion360
            if( actualReport.img_360 !== ''){
                tres60=
                    <div>
                        <div className="boxSectionBody">
                            <p className="SubTitleBody">Entorno 360</p>
                            <iframe src={actualReport.img_360} width="100%" height="450"  allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" allow="acelerómetro; reproducción automática; escritura en portapapeles; medios cifrados; giroscopio; imagen en- imagen completa"/>
                            <div className="box_download_album">
                                <a className="download_album" href={"javascript:window.open('" + actualReport.img_360 + "','','width=600,height=400,left=50,top=50,toolbar=yes');void 0"}>
                                    <Icon style={{ marginLeft: 50 }}>aspect_ratio</Icon> ABRIR EN UNA NUEVA VENTANA
                                </a>
                            </div>
                        </div>
                    </div>
                
            }
            //opcion3D
            if(actualReport.url3D !== ''){
                tresD =
                    <div>
                        <div className="boxSectionBody">
                            <p className="SubTitleBody">Modelo 3D</p>
                            <iframe src={actualReport.url3D} width="100%" height="450"  allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" allow="acelerómetro; reproducción automática; escritura en portapapeles; medios cifrados; giroscopio; imagen en- imagen completa" />
                            <div className="box_download_album">
                                <a className="download_album" href={"javascript:window.open('" + actualReport.url3D + "','','width=600,height=400,left=50,top=50,toolbar=yes');void 0"}>
                                    <Icon style={{ marginLeft: 50 }}>aspect_ratio</Icon> ABRIR EN UNA NUEVA VENTANA
                                </a>
                            </div>
                        </div>   
                    </div>
                
            }

            //opcionVideo
            if(actualReport.urlVideo !== ''){
                video =
                    <div>
                     <div className="boxSectionBody">
                            <p className="SubTitleBody">Registro Histórico Visual</p>
                            <iframe src={actualReport.urlVideo} width="100%" height="450" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" allow="acelerómetro; reproducción automática; escritura en portapapeles; medios cifrados; giroscopio; imagen en- imagen completa"/>
                            {/* <ReactPlayer
                                url={actualReport.urlVideo}
                                className='react-player'
                                width='100%'
                                height='530px'
                                controls={true}
                            /> */}
                             <div className="box_download_album">
                                <a className="download_album" href={"javascript:window.open('" + actualReport.urlVideo + "','','width=600,height=400,left=50,top=50,toolbar=yes');void 0"}>
                                    <Icon style={{ marginLeft: 50 }}>aspect_ratio</Icon> ABRIR EN UNA NUEVA VENTANA
                                </a>
                            </div>
                        </div>  
                    </div>

            }
            return (
                <div>
                    {galeria}
                    {tres60}
                    {tresD}
                    {video}
                </div>
               
            )

  
        
    } else {
            var choosedReport = null;
            for(var i=0; i<date.reports.length; i++){
              if(moment(date.reports[i].date.toDate()).format("MM-YYYY") === moment(date.date.toDate()).format("MM-YYYY")){
                choosedReport = date.reports[i]
              }
            }
            if(choosedReport===null){
                return(
                    <div>
                        <p className="mensajeNo">No existen reportes para el mes seleccionado</p>
                    </div>
                )
            } else {
                    //opcionGaleria
                    if( choosedReport.gallery !==''){
                    
                    galeria = 
                        <div>
                            <div className="boxSectionBody">
                                <GalleryReport report={choosedReport}/>
                            </div>
                        </div>
                    
                    
                    }
                    //opcion360
                    if( choosedReport.img_360 !== ''){
               
                    tres60=
                        <div>
                            <div className="boxSectionBody">
                                <p className="SubTitleBody">Entorno 360</p>
                                <iframe src={choosedReport.img_360} width="100%" height="450"/>
                            </div>
                        </div>
                    
                    }
                    //opcion3D
                    if(choosedReport.url3D !== ''){
                    tresD =
                        <div>
                            <div className="boxSectionBody">
                                <p className="SubTitleBody">Modelo 3D</p>
                                <iframe src={choosedReport.url3D} width="100%" height="450"/>
                            </div>   
                        </div>
                    
                    }

                    //opcionVideo
                    if(choosedReport.urlVideo !== ''){
                    video =
                        <div>
                            <div className="boxSectionBody">
                                <p className="SubTitleBody">Registro Histórico Visual</p>
                                <ReactPlayer
                                    url={choosedReport.urlVideo}
                                    className='react-player'
                                    width='100%'
                                    height='530px'
                                    controls={true}
                                />
                            </div>  
                        </div>

                    }
                    return (
                    <div>
                        {galeria}
                        {tres60}
                        {tresD}
                        {video}
                    </div>
                    
                    )
  
            }
      
        }

}

export default ReportView