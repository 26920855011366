import React from 'react'
import UserSummary from './UserSummary'
import { Link } from 'react-router-dom'
import { Icon } from 'react-materialize'
import ModalDeleteUser from '../modal/ModalDeleteUser'
import { connect } from 'react-redux'

// import { seachProjects } from '../../store/actions/userActions'


const UserList = ({props}) => {
  return (
    <ul className="client-list">
      { props.users && props.users.map(user => {
        return (
       
            <li className="li-list" key={user.uid}>
              <UserSummary user={user}/>          
              <Link to= {'/editarusuario/'+ props.idCliente + '&' + user.id}>
                <div className="div_edit edit_class">
                  <Icon>create</Icon>
                </div>
              </Link>
              <ModalDeleteUser user={user}/>
            </li>
        )
      })}  
    </ul>
  )
}


const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.firebase.auth,
    idCliente: ownProps.idCliente
  }
}

export default connect(mapStateToProps)(UserList)

