import React from 'react';
import ModalContact from './../modal/ModalContact'

const FooterUser = () => {
    let copyright = new Date();
    let update = copyright.getFullYear();

    return (
        <footer className="page-footer footer-fixed">
            <div className="footer-copyright">
                <div className="containerFooter">
                    Copyright ©{update} All rights reserved BIMO
                </div>
                <ModalContact/>
            </div>
        </footer>
     
    )
  }
  
  export default FooterUser