import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { firestoreConnect } from 'react-redux-firebase'
import { Button } from 'react-materialize'
import NavbarUser from '../layout/NavbarUser'
import FooterUser from '../layout/FooterUser'
import NavbarNoUser from '../../components/layout/NavbarNoUser'
 
 
class PilotoUserClose extends Component {
    render() {
        const {projects, idCliente, auth } = this.props;
            if (auth.uid){
                if (projects){

                    return (
                        <div className="userBody">
                            <NavbarUser/>
                            <div className="titleBody">
                                <div className="row">
                                        <div className="col s8 m8 offset-m1">
                                            <div className="textTitleBox">
                                                <i class="fas fa-building"></i>
                                                <h4>PILOTOS CERRADOS</h4>
                                            </div>
                                        </div>
                                </div>
                        
                                <div className="row div_ul">
                                    <div className="col s12 m4" >
                                        <Link to= {{
                                            pathname: `/mapa-pilotos-cerrados/` + idCliente
                                        }}  className="containerImg" >
                                            <div className="boxImgClient">
                                                <img src={"./../img/google_maps.png"} className="proyectImg" alt="mapa proyectos carrados"/>
                                                <div className="capaBoxImgClient"></div>
                                                <div className="textImgClient">
                                                    <h5>UBICACIÓN</h5> 
                                                    <p>DE PROYECTOS</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    {projects.map(project => {
                                        if(project.value ==="CERRADO"  && project.pilotoItem ==="yes"){
                                            return (
                                                <div className="col s12 m4" key={project.id} >
                                                    <Link to= {'/piloto/' + project.id} className="containerImg">
                                                        <div className="boxImgClient">
                                                            <img src={project.img_project} className="proyectImg" alt="imagen de proyecto"/>
                                                            <div className="capaBoxImgClient"></div>
                                                            <div className="textImgClient">
                                                            <h5>{project.name}</h5> 
                                                            <p>{project.place}</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        }
                                    })}  
                                </div>
                                <div className="row boxBtn">
                                    <Link to={'/pilotos/' + idCliente} >
                                        <div className="">
                                            <Button className="btnClient">
                                            Ver Pilotos Actuales
                                            </Button>
                                        </div>
                                    </Link>
                                </div> 
                            </div>
                            <FooterUser/>
                        </div>
                    )
                }
                else {
                    return(
                        <div className="userBody">
                            <NavbarUser/>
                            <div className="titleBody">
                                <div className="row">
                                        <div className="col s8 m8 offset-m1">
                                            <div className="textTitleBox">
                                                <i class="fas fa-building"></i>
                                                <h4>PILOTOS CERRADOS</h4>
                                            </div>
                                        </div>
                                </div>
                                <div className="mensajeNo">Cargando...</div>
                                <div className="row boxBtn">
                                    <Link to={'/pilotos/' + idCliente} >
                                        <div className="">
                                            <Button className="btnClient">
                                            Ver Pilotos Actuales
                                            </Button>
                                        </div>
                                    </Link>
                                </div> 
                            </div>
                            <FooterUser/>
                        </div>
                    )
                }
            } else {
                if (projects){

                    return (
                        <div className="userBody">
                            <NavbarNoUser idCliente={idCliente}/>
                            <div className="titleBody">
                                <div className="row">
                                        <div className="col s8 m8 offset-m1">
                                            <div className="textTitleBox">
                                                <i class="fas fa-building"></i>
                                                <h4>PILOTOS CERRADOS</h4>
                                            </div>
                                        </div>
                                </div>
                        
                                <div className="row div_ul">
                                    <div className="col s12 m4" >
                                        <Link to= {{
                                            pathname: `/mapa-pilotos-cerrados/` + idCliente
                                        }}  className="containerImg" >
                                            <div className="boxImgClient">
                                                <img src={"./../img/google_maps.png"} className="proyectImg" alt="mapa proyectos carrados"/>
                                                <div className="capaBoxImgClient"></div>
                                                <div className="textImgClient">
                                                    <h5>UBICACIÓN</h5> 
                                                    <p>DE PROYECTOS</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    {projects.map(project => {
                                        if(project.value ==="CERRADO"  && project.pilotoItem ==="yes"){
                                            return (
                                                <div className="col s12 m4" key={project.id} >
                                                    <Link to= {'/piloto/' + project.id} className="containerImg">
                                                        <div className="boxImgClient">
                                                            <img src={project.img_project} className="proyectImg" alt="imagen de proyecto"/>
                                                            <div className="capaBoxImgClient"></div>
                                                            <div className="textImgClient">
                                                            <h5>{project.name}</h5> 
                                                            <p>{project.place}</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        }
                                    })}  
                                </div>
                                <div className="row boxBtn">
                                    <Link to={'/pilotos/' + idCliente} >
                                        <div className="">
                                            <Button className="btnClient">
                                            Ver Pilotos Actuales
                                            </Button>
                                        </div>
                                    </Link>
                                </div> 
                            </div>
                            <FooterUser/>
                        </div>
                    )
                }
                else {
                    return(
                        <div className="userBody">
                            <NavbarNoUser  idCliente={idCliente}/>
                            <div className="titleBody">
                                <div className="row">
                                        <div className="col s8 m8 offset-m1">
                                            <div className="textTitleBox">
                                                <i class="fas fa-building"></i>
                                                <h4>PILOTOS CERRADOS</h4>
                                            </div>
                                        </div>
                                </div>
                                <div className="mensajeNo">Cargando...</div>
                                <div className="row boxBtn">
                                    <Link to={'/pilotos/' + idCliente} >
                                        <div className="">
                                            <Button className="btnClient">
                                            Ver Pilotos Actuales
                                            </Button>
                                        </div>
                                    </Link>
                                </div> 
                            </div>
                            <FooterUser/>
                        </div>
                    )
                }
            }
    }
}
 
 
const mapStateToProps = (state, ownProps) => {
    const idCliente = ownProps.match.params.id;
    const projects = state.firestore.ordered.projects;
    return{
        idCliente, idCliente,
        projects: projects,
        auth: state.firebase.auth
    }
}
const projects = 'projects'
export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) =>[
        { collection: 'projects', 
        where: [
            ['idCliente', '==', props.idCliente ]
        ],
        storeAs: projects,
        }
    ])
  )(PilotoUserClose)
 
 
 

