import React, { Component, useState} from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Button } from 'react-materialize'
import NavbarUser from '../layout/NavbarUser'
import FooterUser from '../layout/FooterUser'
import { GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow } from 'react-google-maps'
 
const MyMapComponentClose = withScriptjs(withGoogleMap((props) =>{
    const lat_stgo= -33.44980044389547
    const long_stgo= -70.67088162490161
    const projectsUser = props.props;
    const [selectedProject, setSelectedProject] = useState(null);
    let diferencia = [];
    let diferencia_long = [];
    let index_lat = [];
    
      for (let i = 0; i < projectsUser.length ; i++) {
             if(projectsUser[i].value ==="CERRADO" && projectsUser[i].lat){
             
                 diferencia.push(Math.abs(Math.abs(projectsUser[i].lat) - Math.abs(lat_stgo)))
                 index_lat.push(i)
                 diferencia_long.push(Math.abs(Math.abs(projectsUser[i].long) - Math.abs(long_stgo)))

             }   
      }
      var min = Math.min(...diferencia) 
      var min_long = Math.min(...diferencia_long)

      if(diferencia.length>0){
        return(
            <div className="row">
                <GoogleMap 
                    defaultCenter={{lat: projectsUser[index_lat[diferencia.indexOf(min)]].lat, lng: projectsUser[index_lat[diferencia_long.indexOf(min_long)]].long}}
                    defaultZoom={11} 
                >
                    {projectsUser.map(project => {
                         if(project.value ==="CERRADO"){
                            return (
                            <Marker
                                key={project.id}
                                position={{ lat: project.lat, lng: project.long}}
                                onClick={() => {
                                    setSelectedProject(project);
                                }}
                            />)
                         }
                    })}  
     
                    {selectedProject && (
                        <InfoWindow
                            position={{ lat: selectedProject.lat, lng: selectedProject.long}}
                            onCloseClick={() => {
                                setSelectedProject(null);
                            }}
                        >
                            <div>
                                <p className="textTitleMarker">{selectedProject.name}</p>
                                <p className="textPlaceMarker">{selectedProject.place}</p>
                                <Link to= {'/proyecto/' + selectedProject.id} className="containerImg" target="_blank">
                                    <div className="textLinkMarker">Ver Proyecto</div>
                                </Link>
                            </div>
                        </InfoWindow>
                    )}
                </GoogleMap>
        </div>)
      } else {
        return(
            <div className="row">
                <GoogleMap 
                    defaultCenter={{lat: lat_stgo, lng: long_stgo}}
                    defaultZoom={11} 
                >
                 
                
                </GoogleMap>
        </div>)
      }
 
}))
 
class MapaProjectsClose extends Component {
    render() {
        const { auth, projectsId, projectsUser, idCliente, id} = this.props;
 
        let projectsUserFilter = [];
        let sortedActivities = null
        if (!auth.uid || (auth.uid ==="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/' /> 
 
        if(idCliente && projectsId && projectsUser.length > 0){
            for(var i=0; i<projectsId.length;i++){
                for(var j=0; j<projectsUser.length;j++){
                    if(projectsId[i].idProject === projectsUser[j].id){
                        projectsUserFilter.push(projectsUser[j]);
                    }
                }
            }
            if(projectsUserFilter.length !== 0){
                sortedActivities = projectsUserFilter.sort((a, b) => b.date.seconds - a.date.seconds)
            }
            return (
                <div className="userBody">
                    <NavbarUser/>
                    <div className="titleBody">
                        <div className="row">
                            <div className="col s8 m8 offset-m1">
                                <div className="textTitleBox">
                                    <i className="fas fa-building"></i>
                                    <h4>PROYECTOS CERRADOS</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{width:'80vw', height:"80vh"}}>
                            <MyMapComponentClose
                                props={sortedActivities}
                                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC4czS8nHtrEGpZ6qr4deSLC31XsZIzw9I&v=3.exp&libraries=geometry,drawing,places`}
                                loadingElement={<div style={{height:"100%"}}/>}
                                containerElement={<div style={{height:"100%"}}/>}
                                mapElement={<div style={{height:"100%"}}/>}
                            />
                         </div>
                         <div className="row boxBtn">
                         <Link to= {{
                                pathname: `/mapa-proyectos/` + id
                            }} >
                                    <div className="">
                                        <Button className="btnClient">
                                        Ver proyectos abiertos
                                        </Button>
                                    </div>
                            </Link>
                        </div>
                    </div>
                
                    <FooterUser/> 
                </div>
            )
        }else {
            return(
                <div className="userBody">
                    <NavbarUser/>
                    <div className="mensajeNo">Loading...</div>
                    <FooterUser/> 
                 </div>
            
            )
        }
 
    }
}
 
  
const mapStateToProps = (state, ownProps) => {
 
    const idUser = ownProps.match.params.id;
    const projectsId = state.firestore.ordered.userProjects;
    const users = state.firestore.ordered.users;
    const idCliente = users ? users[0].idCliente : null;
    const projectsTotal = state.firestore.ordered.projects;
    const projectsUser = projectsTotal ? projectsTotal : null;
 
    return{
        auth: state.firebase.auth,
        id: idUser,
        idCliente: idCliente,
        projectsId: projectsId,
        projectsUser: projectsUser
    }
}
const userProjects = 'userProjects'
const projects = 'projects'
 
 
export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) =>[
    { collection: 'users', 
        doc: props.id,
        subcollections: [{ collection: 'idproyects' }],
        where:[
            ['status', '==', true]
        ],
        storeAs: userProjects,
      },
    { collection: 'projects', 
      where: [
        ['idCliente', '==', props.idCliente]
      ],
      storeAs: projects,
    }
    ])
  )(MapaProjectsClose)

