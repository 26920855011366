import React, { Component } from 'react'
import ClientList from '../clients/ClientList'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Navbar from '../../components/layout/Navbar'
import Footer from '../../components/layout/Footer'
import { Link } from 'react-router-dom'
import { Icon } from 'react-materialize'
import { Button } from 'react-materialize'



class Dashboard extends Component {
  
  render() {
    const { clients, auth } = this.props;
    if (!auth.uid || (auth.uid !=="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/admin' /> 
    return (
      <div className="adminBody">
        <Navbar/>
        <div className="dashboard container">
          <div className="row">
            <div className="col s8 m8 offset-m1 add-icon">
              <i class="fas fa-city"></i>
              <h4 className="clients">CLIENTES</h4>
            </div>
            <div className="col s3 col m2 add-icon right">
                <Link to={'/crearcliente'}>
                  <Button
                    floating
                    large
                    className="plus_client"
                    waves="light"
                    icon={  <Icon>
                      add
                      </Icon>}
                  />
                </Link>
            </div>
          </div>
          <div className="">
            <div className="row div_ul">
              <ClientList clients={clients}/>
            </div>
          </div>
        </div>
        <Footer/>
      </div>    
    )
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.firestore.ordered.clientsAdmin,
    auth: state.firebase.auth,
  }
}
const clientsAdmin = 'clientsAdmin'; 
export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    { collection: 'clients', orderBy: ['nameClient'], storeAs: clientsAdmin},
  ])
)(Dashboard)
