import React, { Component, useState} from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Button } from 'react-materialize'
import NavbarUser from '../layout/NavbarUser'
import FooterUser from '../layout/FooterUser'
import { GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow } from 'react-google-maps'
import NavbarNoUser from '../../components/layout/NavbarNoUser'

 
const MyMapComponentClose = withScriptjs(withGoogleMap((props) =>{
    const lat_stgo= -33.44980044389547
    const long_stgo= -70.67088162490161
    const projectsUser = props.props;
    const [selectedProject, setSelectedProject] = useState(null);
    let diferencia = [];
    let diferencia_long = [];
    let index_lat = [];
    
      for (let i = 0; i < projectsUser.length ; i++) {
             if(projectsUser[i].value ==="CERRADO" && projectsUser[i].lat ){
             
                 diferencia.push(Math.abs(Math.abs(projectsUser[i].lat) - Math.abs(lat_stgo)))
                 index_lat.push(i)
                 diferencia_long.push(Math.abs(Math.abs(projectsUser[i].long) - Math.abs(long_stgo)))

             }   
      }
      var min = Math.min(...diferencia) 
      var min_long = Math.min(...diferencia_long)

      if(diferencia.length>0){
        return(
            <div className="row">
                <GoogleMap 
                    defaultCenter={{lat: projectsUser[index_lat[diferencia.indexOf(min)]].lat, lng: projectsUser[index_lat[diferencia_long.indexOf(min_long)]].long}}
                    defaultZoom={11} 
                >
                    {projectsUser.map(project => {
                         if(project.value ==="CERRADO" && project.pilotoItem === "yes"){
                            return (
                            <Marker
                                key={project.id}
                                position={{ lat: project.lat, lng: project.long}}
                                onClick={() => {
                                    setSelectedProject(project);
                                }}
                            />)
                         }
                    })}  
     
                    {selectedProject && (
                        <InfoWindow
                            position={{ lat: selectedProject.lat, lng: selectedProject.long}}
                            onCloseClick={() => {
                                setSelectedProject(null);
                            }}
                        >
                            <div>
                                <p className="textTitleMarker">{selectedProject.name}</p>
                                <p className="textPlaceMarker">{selectedProject.place}</p>
                                <Link to= {'/piloto/' + selectedProject.id} className="containerImg" target="_blank">
                                    <div className="textLinkMarker">Ver Proyecto</div>
                                </Link>
                            </div>
                        </InfoWindow>
                    )}
                </GoogleMap>
        </div>)
      } else {
        return(
            <div className="row">
                <GoogleMap 
                    defaultCenter={{lat: lat_stgo, lng: long_stgo}}
                    defaultZoom={11} 
                >
                 
                
                </GoogleMap>
        </div>)
      }
 
}))
 
class MapaPilotosClose extends Component {
    render() {
        const { projects, idCliente, auth } = this.props;
    
        if (auth.uid){
            if (projects){
                return (
                    <div className="userBody">
                        <NavbarUser/>
                        <div className="titleBody">
                            <div className="row">
                                <div className="col s8 m8 offset-m1">
                                    <div className="textTitleBox">
                                        <i className="fas fa-building"></i>
                                        <h4>PILOTOS CERRADOS</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{width:'80vw', height:"80vh"}}>
                                <MyMapComponentClose
                                    props={projects}
                                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC4czS8nHtrEGpZ6qr4deSLC31XsZIzw9I&v=3.exp&libraries=geometry,drawing,places`}
                                    loadingElement={<div style={{height:"100%"}}/>}
                                    containerElement={<div style={{height:"100%"}}/>}
                                    mapElement={<div style={{height:"100%"}}/>}
                                />
                            </div>
                            <div className="row boxBtn">
                                    <Link to= {{
                                        pathname: `/mapa-pilotos/` + idCliente 
                                    }} >
                                    <div className="">
                                        <Button className="btnClient">
                                        Ver pilotos abiertos
                                        </Button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    
                        <FooterUser/> 
                    </div>
                )
            }else {
                return(
                    <div className="userBody">
                        <NavbarUser/>
                        <div className="mensajeNo">Cargando...</div>
                        <FooterUser/> 
                    </div>
                
                )
            }
        } else {
            if ( projects){
                return (
                    <div className="userBody">
                        <NavbarNoUser idCliente={idCliente}/>
                        <div className="titleBody">
                            <div className="row">
                                <div className="col s8 m8 offset-m1">
                                    <div className="textTitleBox">
                                        <i className="fas fa-building"></i>
                                        <h4>PILOTOS CERRADOS</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{width:'80vw', height:"80vh"}}>
                                <MyMapComponentClose
                                    props={projects}
                                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyC4czS8nHtrEGpZ6qr4deSLC31XsZIzw9I&v=3.exp&libraries=geometry,drawing,places`}
                                    loadingElement={<div style={{height:"100%"}}/>}
                                    containerElement={<div style={{height:"100%"}}/>}
                                    mapElement={<div style={{height:"100%"}}/>}
                                />
                            </div>
                            <div className="row boxBtn">
                                    <Link to= {{
                                        pathname: `/mapa-pilotos/` + idCliente 
                                    }} >
                                    <div className="">
                                        <Button className="btnClient">
                                        Ver pilotos abiertos
                                        </Button>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    
                        <FooterUser/> 
                    </div>
                )
            }else {
                return(
                    <div className="userBody">
                        <div className="mensajeNo">Cargando...</div>
                        <FooterUser/> 
                    </div>
                
                )
            }
        }
            
 
    }
}
 
 
 
const mapStateToProps = (state, ownProps) => {

    const idCliente = ownProps.match.params.id;
    const projects = state.firestore.ordered.projects;
 
    return{
        idCliente, idCliente,
        projects: projects,
        auth: state.firebase.auth
    }
}
const projects = 'projects'
 
 
export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) =>[
        { collection: 'projects', 
        where: [
          ['idCliente', '==', props.idCliente]
        ],
        storeAs: projects,
      }
    ])
  )(MapaPilotosClose)
 
 


