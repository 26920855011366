import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Button } from 'react-materialize'
 
 
class ProjectListUser extends Component {
    render() {
      const { projectsId, projectsUser, idUser, idCliente } = this.props;
      let projectsUserFilter = [];
      let sortedActivities = null
      if (projectsId && projectsUser && projectsId.length > 0 && projectsUser.length > 0){
            for(var i=0; i<projectsId.length;i++){
                for(var j=0; j<projectsUser.length;j++){
                    if(projectsId[i].idProject === projectsUser[j].id){
                        projectsUserFilter.push(projectsUser[j]);
                    }
                }
            }
            if(projectsUserFilter.length !== 0){
                sortedActivities = projectsUserFilter.sort((a, b) => b.date.seconds - a.date.seconds)
            }
            return (
                <div className="userBody">
                    <div className="row">
                        <div className="col s12 m4" >
                            <Link to= {{
                                pathname: `/mapa-proyectos/` + idUser 
                            }} className="containerImg" >
                                <div className="boxImgClient">
                                    <img src={"./../img/google_maps.png"} className="proyectImg" alt="mapa proyectos"/>
                                    <div className="capaBoxImgClient"></div>
                                    <div className="textImgClient">
                                        <h5>UBICACIÓN</h5> 
                                        <p>DE PROYECTOS</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {sortedActivities.map(project => {
                            if(project.value ==="ABIERTO"){
                                return (
                                    <div className="col s12 m4" key={project.id} >
                                        <Link to= {'/proyecto/' + project.id} className="containerImg" >
                                            <div className="boxImgClient">
                                                <img src={project.img_project ? project.img_project : "./../img/proyecto.jpg"} className="proyectImg" alt="imagen de proyecto"/>
                                                <div className="capaBoxImgClient"></div>
                                                <div className="textImgClient">
                                                   <h5>{project.name}</h5> 
                                                   <p>{project.place}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            }
                        })}  
                    </div>
                    <div className="row boxBtn">
                        <Link to={'proyectos-cerrados'} >
                            <div className="">
                                <Button className="btnClient">
                                Ver proyectos cerrados
                                </Button>
                            </div>
                        </Link>
                    </div>
                </div>
            )
        } else {
            return(
                <div className="userBody">
                    <div className="mensajeNo">Cargando...</div>
                </div>
            )
        }
    }
}
 
 
const mapStateToProps = (state, props) => {
// console.log(state)
    const propsId = Object.values(props)
    const idUser = propsId[0];
    const idCliente = propsId[1];
    const projectsId = state.firestore.ordered.userProjects;
    const projectsUser = state.firestore.ordered.projects;
    // console.log(projectsId)
    return{
        auth: state.firebase.auth,
        id: idUser,
        idCliente, idCliente,
        projectsId: projectsId,
        projectsUser: projectsUser
    }
}
const userProjects = 'userProjects'
export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) =>[
    { collection: 'users', 
        doc: props.id,
        subcollections: [{ collection: 'idproyects' }],
        where:[
            ['status', '==', true]
        ],
        storeAs: userProjects,
      },
    { collection: 'projects', 
      where: [
        ['idCliente', '==', props.idCliente]
      ]
    }
    ])
  )(ProjectListUser)
 

