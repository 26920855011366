const initState = {
    successGallery_p: null,
    successZip: null,
    deletePilotoTrue: null
  }
  
  const pilotoReducer = (state = initState, action) => {
    switch (action.type) {
      case 'CREATE_PILOTO_SUCCESS':
        return {
          ...state,
          successGallery_p: null,
          successZip: null
        }
      case 'CREATE_PILOTO_ERROR':
        return {
          ...state,
          successGallery_p: null,
          successZip: null
        }
        case 'SET_URL_PILOTO_GALLERY':
          return {
            ...state,
            successGallery_p: "Galería creada"
          }
        case 'SET_URL_ZIP':
          return {
            ...state,
            successZip: "Zip creado"
          }
          case 'DELETE_PILOTO':
            return {
              ...state,
              deletePilotoTrue: "Piloto eliminado"
            }
            case 'DELETE_PILOTO_ERROR':
              return {
                ...state,
                deletePilotoTrue: null
              }
       case 'SUCCESS_CLEAN': 
         return{
          ...state,
          successGallery_p: null,
          successZip: null,
          deletePilotoTrue: null
         }
      default:
        return state;
    }
  };
  
  export default pilotoReducer;