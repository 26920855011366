const initState = {
  successGallery: null,
  successZip: null,
  success360: null
}

const reportReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_REPORT_SUCCESS':
      return {
        ...state,
        successGallery: null,
        successZip: null,
        success360: null
      }
    case 'CREATE_REPORT_ERROR':
      return {
        ...state,
        successGallery: null,
        successZip: null,
        success360: null
      }
      case 'SET_URL_REPORT_GALLERY':
        return {
          ...state,
          successGallery: "Galería creada"
        }
      case 'SET_URL_ZIP':
        return {
          ...state,
          successZip: "Zip creado"
        }
      case 'SET_URL_360':
          return {
            ...state,
            success360: "360 creado"
          }
     case 'SUCCESS_CLEAN': 
       return{
        ...state,
        successGallery: null,
        successZip: null,
        success360: null
       }
    default:
      return state;
  }
};

export default reportReducer;