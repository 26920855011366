import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import LinksNoUser from './LinksNoUser'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Navbar, Icon } from 'react-materialize'


class NavbarNoUser extends Component {
  

  render() {

        const { cliente } = this.props;
        const links = <LinksNoUser  cliente={cliente ? cliente : null}/>;
        if(cliente){
            return (
                <Navbar className="white"
                    alignLinks="right"
                    brand={
                        <Link to='/'>
                            <img src={cliente[0].url_logo? cliente[0].url_logo : null} className="brand-logos log_nav" alt='logo'/>
                        </Link>
                        }
                    id="mobile-nav"
                    menuIcon={<Icon>menu</Icon>}
                    options={{
                        draggable: true,
                        edge: 'left',
                        inDuration: 250,
                        onCloseEnd: null,
                        onCloseStart: null,
                        onOpenEnd: null,
                        onOpenStart: null,
                        outDuration: 200,
                        preventScrolling: false
                    }}
                >
                    {links}
                </Navbar>
            )
        } else {
            return (
                <nav className="nav-wrapper">
                    <div className="container">
                        <Link to='/'>
                        <img alt='cangando...'/>
                        </Link>
                        {links}
                    </div>
                </nav>
            )
        }
       
        }
    }
  
  


const mapStateToProps = (state, props) => {
    const cliente = state.firestore.ordered.cliente;
    const idCliente = props.idCliente
    return{
        cliente: cliente,
        idCliente: idCliente
    }
}
const cliente = 'cliente'

export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) =>[
      { collection:'clients',
        doc: props.idCliente,
        storeAs: cliente
      }
    ])
  )(NavbarNoUser)
