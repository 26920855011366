import React, { Component } from 'react'
import { connect } from 'react-redux'
import { editClient } from '../../store/actions/clientActions'
import { Redirect } from 'react-router-dom'
import Navbar from '../../components/layout/Navbar'
import Footer from '../../components/layout/Footer'

class EditClient extends Component {
  state = {
    nameClient: '',
    logo_client:'',
    id: this.props.id
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value.toUpperCase(),
    })
  }

  handleChargeFile = (e) => {
    e.preventDefault();
    this.setState({ logo_client: e.target.files[0] });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.editClient(this.state);
    this.props.history.push('/dashboard');
  }
  render() {
    const { auth, client } = this.props;
    if (!auth.uid || (auth.uid !=="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/admin' /> 
    if (client !== null) {    
      return (
          <div className="adminBody">
            <Navbar/>
            <div className="container">
            <div className="row">
                <div className="col s12 m10 offset-m1 add-icon">
              <form className="white" onSubmit={this.handleSubmit}>
                  <i class="fas fa-city"></i>
                  <h5 className="titleFormCreate">EDITAR {client.nameClient}</h5>
                  <div className="input-field">
                    <input type="text" id='nameClient' onChange={this.handleChange} />
                    <label htmlFor="nameClient">{client.nameClient}</label>
                  </div>
                  <div className="file-field input-field">
                      <div className="btn btn-login">
                        <span>Cargar Logo</span>
                        <input type="file" onChange={this.handleChargeFile} accept="image/x-png,image/gif,image/jpeg"/>
                      </div>
                      <div className="file-path-wrapper">
                        <input className="file-path validate" readonly="readonly" type="text"  />
                        <span>Nombre del Archivo:</span>
                      </div>
                  </div>
                  <div className="input-field center">
                    <button className="btn btn-login">Actualizar</button>
                  </div>
                </form>
                <div className="container-preview">
                  <div className="row">
                    <div className="col s12 m8 offset-m2">
                      <div className="boxPreviewImg">
                        <h6>Logo cargado previamente</h6>
                        <img className="logo_client center" src={client.url_logo} alt="sin_logo_cliente"/>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                </div>
              </div>
            <Footer/>
          </div>

    )
  } else {
    return(
      <div className="adminBody">
        <Navbar/>
        <div className="container">
        </div>
    </div>
    )
    
  }

  }
}

const mapStateToProps = (state, ownProps) => {
  console.log(state)
  const id = ownProps.match.params.id;
  const clients = state.firestore.data.clientsAdmin;
  const client = clients ? clients[id] : null
  return {
    id : id,
    client: client,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    editClient: (client) => dispatch(editClient(client))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditClient)
