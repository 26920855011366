import React, { Component } from 'react'
import UserList from '../users/UserList'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Navbar from '../../components/layout/Navbar'
import Footer from '../../components/layout/Footer'
import { Link } from 'react-router-dom'
import { Icon } from 'react-materialize'
import { Button } from 'react-materialize'


class DashboardUsers extends Component {
 
  render() {
   
    const { auth, idCliente} = this.props;
    if (!auth.uid || (auth.uid !=="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/admin' /> 

    return (
      <div className="adminBody">
        <Navbar/>
        <div className="dashboard container">
          <div className="row">
            <div className="col s8 m8 offset-m1 add-icon">
              <i className="fas fa-user-circle"></i>
              <h4 className="clients">USUARIOS</h4>
            </div>
            <div className="col s3 col m2 add-icon right">
                <Link to={'/crearusuario/' + idCliente} key={idCliente}>
                  <Button
                    floating
                    large
                    className="plus_client"
                    waves="light"
                    icon={  <Icon>
                      add
                      </Icon>}
                  />
                </Link>
            </div>
          </div>
          <div className="">
            <div className="row div_ul">
              <UserList props={this.props} />
            </div>
          </div>
        </div>
        <Footer/>
      </div>    
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const idcliente =  ownProps.match.params.id
  return {
    users: state.firestore.ordered.users,
    auth: state.firebase.auth,
    idCliente: idcliente
  }
}
export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    { collection: 'users', 
      where: [
        ['idCliente', '==', props.idCliente]
      ]
      // doc: props.users.id,
      // subcollections: [{ collection: 'idproyects' }]
    }
  ])
)(DashboardUsers)
