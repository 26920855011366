import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import NavbarUser from '../../components/layout/NavbarUser'
import ReportSummaryList from './ReportSummaryList'
import FooterUser from '../layout/FooterUser'
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import "moment/locale/es";

moment.locale("es");

class ReportDetailsUser extends Component {
    state = {
        selectedDate: null
      }
    
      handleDateChange = date => {
        this.setState({ selectedDate: date })
      }

    render() {    
      const { auth, reports } = this.props; 
      const { selectedDate } = this.state;
    //   console.log(this.state.selectedDate)
      if (!auth.uid || (auth.uid ==="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/' /> 
      if (reports && reports.length > 0){    
        var dates = [];
        for(var i=0; i<reports.length;i++){
          dates.push(new Date(moment(reports[i].date.toDate()).format("YYYY-MM-DD")));
        }
        var actualDate=new Date(Math.max.apply(null, dates)); 
        // console.log(actualDate)
        var olderDate = new Date(Math.min.apply(null, dates));
        return(
            <div className="userBody">  
                <NavbarUser/>
                    <div className="container">
                         <div className="titleBody">
                            <div className="textTitleBox secondTextMargin">
                                    <i className="fas fa-briefcase"></i>
                                    <h4>Registros Descargables</h4>
                            </div>
                        </div>
                        <div className="boxDatePicker">
                        <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                            <DatePicker
                                views={["year"]}
                                label="Fecha de Reporte"
                                minDate={olderDate}
                                maxDate={actualDate}
                                value={selectedDate ? selectedDate : actualDate}
                                onChange={this.handleDateChange}
                                minDateMessage=''
                            />
                        </MuiPickersUtilsProvider>
                        </div>
                        <div className="boxTableReports">
                            <ReportSummaryList date={this.state.selectedDate} reports={reports}/>
                        </div>
                    </div>
                <FooterUser/>
            </div>
        )
    }else{
        return (
            <div className="userBody">
                 <NavbarUser/>
                 <div className="mensajeNo">No hay reportes asociados a este proyecto</div>
                <FooterUser/>
            </div>
        )
      }
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const projects = state.firestore.data.projects;
    const project = projects ? projects[id] : null
    const reports = state.firestore.ordered.reportsProject;
    return{
        auth: state.firebase.auth,
        project: project,
        id: id,
        reports: reports
    }
}

const reports = 'reportsProject'

export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) =>[
        { collection: 'reports',
        where: [
            ['idProject', '==', props.id]
        ],
        storeAs: reports,
      } 
    ])
  )(ReportDetailsUser)