import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Navbar from '../../components/layout/Navbar'
import Footer from '../../components/layout/Footer'
import { Link } from 'react-router-dom'
import { Icon } from 'react-materialize'
import { Button } from 'react-materialize'



class ProjectOptions extends Component {
// const ProjectOptions = (props) => {
  // constructor(props) {
  //   super(props);
  //   this.state = {

  //   }
  // }
  render() {
    const { auth, id, projects, pilotos} = this.props;
    if (!auth.uid || (auth.uid !=="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/admin' /> 
    // console.log(statePiloto)
    // console.log(nameProject)
    // console.log(pilotos)

      // console.log("statePiloto && pilotos")
      if (projects){
        if(projects[0].pilotoItem === "yes" && pilotos && pilotos.length>0){
          // console.log("statePiloto=== 'yes' && pilotos.length>0")
          //******************************************** */
          // return (
          //   <div className="adminBody">
          //     <Navbar/>
          //     <div className="container section">
          //       <div className="row">
          //         <div className="col s12 m10 offset-m1">
          //           <i class="fas fa-city"></i>
          //           <h4 className="clients">{nameProject +': OPCIONES'}</h4>
          //         </div>
          //       </div>
          //       <div className="row">
          //         <div className="mensajeNo">Cargando... </div>
          //       </div>
          //     </div>
          //    <Footer/>
          //   </div>
      
          // )
          //******************************************* */
          return (
            <div className="adminBody">
              <Navbar/>
              <div className="container section">
                <div className="row">
                  <div className="col s12 m10 offset-m1">
                    <i class="fas fa-city"></i>
                    <h4 className="clients">{projects[0].name +': OPCIONES'}</h4>
                  </div>
                </div>
                <div className="row">
                    <Link to={'/reportes/' + id} key={id}>
                    <div className="">
                      <Button className="project_button" waves="light">
                        <i class="fas fa-building left"></i>
                        REPORTES
                      </Button>
                    </div>
                    </Link>
                </div>
                <div className="row">
                    <Link to={'/editar-piloto/' + id + '&' + pilotos[0].id} key={id}>
                    <div className="dashboard">
                      <Button className="users_button" waves="light">
                        <Icon className="left">camera_alt</Icon>
                        PILOTO-EDITAR
                      </Button>
                    </div>
                    </Link>
                </div>
              </div>
            <Footer/>
            </div>
      
          )
        } else if (projects[0].pilotoItem ==="no" || !projects[0].pilotoItem) {
          // console.log("statePiloto==='no'")
          return (
            <div className="adminBody">
              <Navbar/>
              <div className="container section">
                <div className="row">
                  <div className="col s12 m10 offset-m1">
                    <i class="fas fa-city"></i>
                    <h4 className="clients">{projects[0].name  +': OPCIONES'}</h4>
                  </div>
                </div>
                <div className="row">
                    <Link to={'/reportes/' + id} key={id}>
                    <div className="">
                      <Button className="project_button" waves="light">
                        <i class="fas fa-building left"></i>
                        REPORTES
                      </Button>
                    </div>
                    </Link>
                </div>
                <div className="row">
          
                    <Link to={'/crear-piloto/' + id } key={id}>
                    <div className="dashboard">
                      <Button className="users_button" waves="light">
                      <Icon className="left">camera_alt</Icon>
                        PILOTO-CREAR
                      </Button>
                    </div>
                    </Link>
                  
      
            
                </div>
              </div>
            <Footer/>
            </div>
      
          )
        } else {
          return (
            <div className="adminBody">
              <Navbar/>
              <div className="container section">
                <div className="row">
                  <div className="col s12 m10 offset-m1">
                    <i class="fas fa-city"></i>
                    <h4 className="clients">{projects[0].name +': OPCIONES'}</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="mensajeNo">Cargando... </div>
                </div>
              </div>
            <Footer/>
            </div>
      
          )
        }
      } else {
        return (
          <div className="adminBody">
            <Navbar/>
            <div className="container section">
              <div className="row">
                <div className="mensajeNo">Cargando... </div>
              </div>
            </div>
          <Footer/>
          </div>
    
        )
      }

    }
  }
  



const mapStateToProps = (state, ownProps) => {
 
    const id = ownProps.match.params.id;
    const projects = state.firestore.ordered.projects ? state.firestore.ordered.projects : null;
    const pilotos = state.firestore.ordered.pilotos
    // var nameProject = null;
    // var statePiloto = null;
    // console.log("se ejecuta de nuevo " + statePiloto)
    // if(projects){ 
    //   for(var i=0; i<projects.length; i++){
    //       if(projects[i].id === id){
         
    //         nameProject = projects[i].name
    //         if(projects[i].pilotoItem){
    //           statePiloto = projects[i].pilotoItem
    //         } else {
    //           statePiloto = "no"
    //         }
    //       }
         
    //     }
    // }
    // console.log("se ejecuta de nuevo " + statePiloto)
    return {
      id : id,
      auth: state.firebase.auth,
      // nameProject: nameProject,
      // statePiloto: statePiloto,
      pilotos: pilotos,
      projects: projects
    }
  }
  
  const pilotos = 'pilotos';
  const projects = 'projects'

  export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) =>[
      { collection: 'projects',
      doc: props.id,
      storeAs: 'projects'
    },
      { collection: 'pilotos', 
      where: [
        ['idProject', '==', props.id]
      ],
      storeAs: 'pilotos'
      },
])
  )(ProjectOptions)
  
