import React from 'react'
import ClientSummary from './ClientSummary'
import { Link } from 'react-router-dom'
import { Icon } from 'react-materialize'
import ModalDeleteClient from '../modal/ModalDeleteClient'


const ClientList = ({clients}) => {
  return (
    <ul className="client-list">
      { clients && clients.map(client => {
        return (
            <li className="li-list" key={client.id}>
              <Link to={'/cliente/' + client.id} >
                <ClientSummary client={client}/>
              </Link>
              <Link to= {'/editarcliente/' + client.id}>
                <div className="div_edit edit_class">
                  <Icon>create</Icon>
                </div>
              </Link>
              <ModalDeleteClient client={client}/>
            </li>
        )
      })}  
    </ul>
  )
}

export default ClientList
