import React from 'react';
import { connect } from 'react-redux';
import { recoverPass } from '../../store/actions/authActions'
import ReactModal from 'react-modal';

class Modal extends React.Component {
  constructor () {
    super();
    this.state = {
      showModal: false,
      email_recover: ''
    };
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  
  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleClick = (e) => {
      e.preventDefault();
      this.props.recoverPass(this.state.email_recover);
      this.setState({ showModal: false });
  }
  
  render () {
    const { successRecover } = this.props;
    return (
      <div>
         <div className="center recoverPass">
            <a className="modal_trigger reset_password" onClick={this.handleOpenModal}>¿Olvidaste la contraseña?</a>
            <div className="center reset_password color_recover">
               { successRecover ? <p>{successRecover}</p> : null }
            </div>
        </div>
      
        <ReactModal
           isOpen={this.state.showModal}
           contentLabel="onRequestClose"
           onRequestClose={this.handleCloseModal}
        >
              <div className="passRecovery">
                  <h5 className="title_access">Para recuperar tu contraseña por favor ingresa tu correo:</h5>
                  <div className="input-field">
                    <label htmlFor="email_recover">Ingresa tu correo</label>
                    <input type="email" id='email_recover' onChange={this.handleChange} />
                  </div>
               </div>
                <div className="center">
                  <a className="btn btn-login" onClick={this.handleClick} >Enviar</a>
                </div>
         
        </ReactModal>
      </div>
    );
  }
}
    
  const mapStateToProps = (state) => {
    return{
      successRecover: state.auth.successRecover,
      authError: state.auth.authError
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      recoverPass: (creds) => dispatch(recoverPass(creds))
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(Modal)

  