import { storage } from "../../config/fbConfig";
 
export const createProject = (project) => {
  return (dispatch, getState, {getFirestore}) => {
    const firestore = getFirestore();
    firestore.collection('projects').add({
      name: project.name,
      place: project.place,
      value: project.value,
      date: project.date,
      idCliente: project.idCliente,
      lat: Number(project.lat),
      long: Number(project.long)
    }).then((docId) => {
      dispatch({ type: 'CREATE_PROJECT_SUCCESS' });
      const storageRef = storage.ref("photo_projects_principal/")
      storageRef.child(docId.id).put(project.img_project).then((snapshot)=> {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          firestore.collection('projects').doc(docId.id).update({
            img_project: downloadURL
          }).then(() => {
            dispatch({ type: 'SET_URL_PROJECT_PRINCIPAL' });
          })
        });
      });
    }).catch(err => {
      dispatch({ type: 'CREATE_PROJECT_ERROR' }, err);
    });
  }
};
 
export const deleteProject = (project) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('projects').doc(project.id).delete() 
    .then(() => {
      dispatch({ type: 'DELETE_PROJECT_SUCESS' });
    }).catch(err=>{
      dispatch({ type: 'DELETE_PROJECT_ERROR' }, err);
    })
  }
};
 
export const editProject = (project) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    if (project.name !== '') {
      firestore.collection('projects').doc(project.id).update({
        name: project.name
      }).then(() => {
        dispatch({ type: 'CREATE_PROJECT_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CREATE_PROJECT_ERROR' }, err);
      });  
    }
    if (project.place !== '') {
      firestore.collection('projects').doc(project.id).update({
        place: project.place
      }).then(() => {
        dispatch({ type: 'CREATE_PROJECT_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CREATE_PROJECT_ERROR' }, err);
      });  
    }
    if (project.lat !== '') {
      firestore.collection('projects').doc(project.id).update({
        lat: Number(project.lat)
      }).then(() => {
        dispatch({ type: 'CREATE_PROJECT_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CREATE_PROJECT_ERROR' }, err);
      });  
    }
    if (project.long !== '') {
      firestore.collection('projects').doc(project.id).update({
        long: Number(project.long)
      }).then(() => {
        dispatch({ type: 'CREATE_PROJECT_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CREATE_PROJECT_ERROR' }, err);
      });  
    }
    if (project.value !== '') {
      firestore.collection('projects').doc(project.id).update({
        value: project.value
      }).then(() => {
        dispatch({ type: 'CREATE_PROJECT_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CREATE_PROJECT_ERROR' }, err);
      });  
    }
    if (project.date !== '') {
      firestore.collection('projects').doc(project.id).update({
        date: project.date
      }).then(() => {
        dispatch({ type: 'CREATE_PROJECT_SUCCESS' });
      }).catch(err => {
        dispatch({ type: 'CREATE_PROJECT_ERROR' }, err);
      });  
    }
    if (project.img_project !== '') {
      const storageRef = storage.ref("photo_projects_principal/")
      storageRef.child(project.id).put(project.img_project).then((snapshot)=> {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          firestore.collection('projects').doc(project.id).update({
            img_project: downloadURL
          }).then(() => {
            dispatch({ type: 'SET_URL_PROJECT_PRINCIPAL' });
          })
        });
      });
    }
  }
};

