import React, { useEffect }from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'
import { Icon, NavItem } from 'react-materialize'



const SignedInLinksUserNoPilotos = (props) => {

  const clienteID = props.client

  if(clienteID){
    return (
      <div className='interNav'>
        <NavLink to='/proyectos' className="color_links">
          <i class="material-icons">business</i>PROYECTOS
        </NavLink>
        <NavLink to='/entregables' className="color_links" activeClassName="active">
          <i class="material-icons">business_center</i>ENTREGABLES
        </NavLink>
          <NavItem className="logout color_links" onClick={props.signOut}>
            <Icon medium>power_settings_new</Icon>SALIR
          </NavItem>
      </div>
    )
  } else {
    return (
      <div className='interNav'>
      </div>
    )
  }
}


const mapDispatchToProps = (dispatch) => {
    return {
      signOut: () => dispatch(signOut())
    }
  }
  
  export default connect(null, mapDispatchToProps)(SignedInLinksUserNoPilotos)
