import React from 'react'
import { Link } from 'react-router-dom'
import SignedInLinks from './SignedInLinks'
import { connect } from 'react-redux'

const Navbar = (props) => {
  const { profile } = props;
  const links = <SignedInLinks profile={profile} />;

  return (
    <nav className="nav-wrapper">
      <div className="container">
        <Link to='/dashboard'>
          <img src="./../img/logo.png" className="brand-logo log_nav" alt="BIMO"/>
        </Link>
        {links}
      </div>
    </nav>
  )
}

const mapStateToProps = (state) => {
  return{
    auth: state.firebase.auth,
  }
}

export default connect(mapStateToProps)(Navbar)
