import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import Navbar from '../../components/layout/Navbar'
import Footer from '../../components/layout/Footer'
import { Link } from 'react-router-dom'
import { Button } from 'react-materialize'
import { firestoreConnect } from 'react-redux-firebase'



const ClientDetails = (props) => {
  const { client, auth, id} = props;

  if (!auth.uid || (auth.uid !=="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/admin' /> 
  if (client && client.length>0) {
    const links = client[0].url_logo ? client[0].url_logo : './../img/no-image.png';

    return (
      <div className="adminBody">
        <Navbar/>
        <div className="container section">
          <div className="row">
            <div className="col s12 m10 offset-m1">
              <i class="fas fa-city"></i>
              <h4 className="title-projects clients">{client[0].nameClient}</h4>
              <div className="center">
               <img className="logo_client" src={links} alt="sin_logo_cliente"/>
              </div>
            </div>
          </div>
          <div className="row">
              <Link to={'/proyectos/' + id} key={id}>
              <div className="">
                <Button className="project_button" waves="light">
                  <i class="fas fa-building left"></i>
                  PROYECTOS
                </Button>
              </div>
              </Link>
          </div>
          <div className="row">
              <Link to={'/usuarios/' + id} key={id}>
              <div className="dashboard">
                <Button className="users_button" waves="light">
                  <i class="fas fa-user-circle left"></i>
                  USUARIOS
                </Button>
              </div>
              </Link>
          </div>
        </div>
       <Footer/>
      </div>

    )
  } else {
    return (
      <div className="adminBody">
         <Navbar/>
         <div className="container center">   
          <div className="mensajeNo">Cargando...</div>   
        </div>
        <Footer/>
      </div>
     
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const clients = state.firestore.ordered.clientsAdmin;
  const client = [];

  if(clients){ 
    for(var i=0; i<clients.length; i++){
        if(clients[i].id === id){
          client.push(clients[i])
        }
      }
  }

  return {
    id : id,
    clients: clients,
    auth: state.firebase.auth,
    client: client

  }
}
const clientsAdmin = 'clientsAdmin'
export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    { collection: 'clients', 
      doc: props.id,
      storeAs: clientsAdmin
    }
  ])
)(ClientDetails)