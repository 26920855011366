import React from 'react'
import GalleryPiloto from './GalleryPiloto';
// import Image360Report from './360ImageReport';
import moment from 'moment';
import ReactPlayer from 'react-player'
import { Icon } from 'react-materialize';
import FooterUser from '../layout/FooterUser'


const PilotoGallery =(pilotos)=> {

    let galeria;
    let zip;

   if(pilotos){

    let piloto = pilotos.pilotos.pilotos[0]

    //opcionGaleria
    if(piloto.gallery !==''){
        galeria = 
            <div>
                <div className="boxSectionBody">
                    <GalleryPiloto piloto={piloto}/>
                </div>
            </div>
      
       
    }
    if(piloto.zip !==''){
        zip = 
            <div className="box_download_album">
                <a className="download_album" href={piloto.zip}>
                <Icon style={{ marginLeft: 50 }}>archive</Icon> DESCARGAR IMÁGENES
                </a>
            </div>
      
       
    }

    return (
        <div>
            {galeria}
            {zip}
        </div>
       
    )
   }    else {
            return(
                <div className="userBody">
                <div className="mensajeNo">Cargando...</div>
                <FooterUser/> 
                </div>
            )
   }
}

export default PilotoGallery