import React, { useState, useEffect  } from 'react'
import { Button } from 'react-materialize'
import { Icon } from 'react-materialize'


const CreateRecorrido =({props}) => {

 const  handleRecorridoUrl  = props

  const [urlRecorridos, seturlRecorridos] = useState([]);  
  

  const addInputButton = (e) => {
      e.preventDefault()
      seturlRecorridos([ ... urlRecorridos, ""])
    
  }

  const handleChangeRecorrido = (e, index) => {
    urlRecorridos[index] = e.target.value;
    seturlRecorridos([ ... urlRecorridos]);
  }

  const handleDeleteInputRecorrido = (position) =>{
    seturlRecorridos((urlRecorridos) => urlRecorridos.filter((_, index) => index !== position));
  }


    useEffect(() => {
        handleRecorridoUrl(urlRecorridos);
    }, [urlRecorridos]); 


  return (

      <div>
        <div className="add_item">
            <Button 
                onClick={addInputButton}
                floating
                large
                className="plus_client"
                waves="light"
                icon={  <Icon>
                            add
                        </Icon>}
            />
            <label>Añadir Url de Recorridos</label>
            </div>
            {urlRecorridos.map((urlRecorrido, index) =>(
                <div key={index} style={{display:'flex'}}>
                    <input className="input_edit_admin"  type="text" name="urlRecorrido" value={urlRecorrido} id={`urlRecorrido-${index+1}`} placeholder={`urlRecorrido-${index+1}`} onChange={(e)=> handleChangeRecorrido(e, index)}/>
                    {/* <label htmlFor="urlVideo"></label> */}
                    <Button className="btn_delete_admin"
                        onClick={() => {handleDeleteInputRecorrido(index)}}
                        icon={  <Icon>
                                  delete
                                </Icon>}
                    />
                </div>
  
            ))}
           
          
      </div>
     
  )
  

}

export default CreateRecorrido