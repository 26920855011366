import React, { useState, useEffect , useCallback } from 'react'
import { Button } from 'react-materialize'
import { Icon } from 'react-materialize'


const EditVideo =({props, pilotos}) => {

 const  handleVideoUrl  = props;
 
  const [urlVideos, setUrlVideos] = useState([]);  
  const [urlVideosPrev, setUrlVideosPrev] = useState([]);  
  const [urlVideosTotal, setUrlVideosTotal] = useState([]);


  const addInputButton = (e) => {
      e.preventDefault()
      setUrlVideos([ ... urlVideos, ""])
    
  }

  const addVideosTotal = () =>{
    setUrlVideosTotal(urlVideosPrev.concat(urlVideos));
  }

  const handleChangeVideo = (e, index) => {
     urlVideos[index] = e.target.value;
     setUrlVideos([ ... urlVideos]);
     
  }
  
  const handleDeleteInputVideo = (position) =>{
      setUrlVideos((urlVideos) => urlVideos.filter((_, index) => index !== position));
      
  
  }
  const handleDeleteInputVideoPrev = (position) =>{
    setUrlVideosPrev((urlVideosPrev) => urlVideosPrev.filter((_, index) => index !== position));   
}
useEffect(() => {
    setUrlVideosPrev(pilotos);
}, [pilotos]); 

    useEffect(() => {
        addVideosTotal()
    }, [ urlVideos]); 

    useEffect(() => {
        addVideosTotal()
    }, [ urlVideosPrev]); 


    useEffect(() => {
        handleVideoUrl(urlVideosTotal);
    }, [ urlVideosTotal]); 


  return (
      <div className="box_edits_inputs_admin">
            <label className="title_edit_admin">Url de Videos</label>
            {urlVideosPrev.map((urlV, index) =>(          
                urlV ? 
                <div key={index +'urlV'} style={{display:'flex'}}>
                    <input className="input_edit_admin" disabled type="text" name="urlV" value={urlV} id={`urlV-${index+1}`} placeholder={`urlV-${index+1}`}/>
                    <Button  className="btn_delete_admin"
                        onClick={() => {handleDeleteInputVideoPrev(index)}}
                        icon={  <Icon>
                                  delete
                                </Icon>}
                    />
                </div>
                : null  
            ))}
            <div className="add_item">
            <Button 
                onClick={addInputButton}
                floating
                large
                className="plus_client"
                waves="light"
                icon={  <Icon>
                            add
                        </Icon>}
            />
       
            <label>Añadir nuevos videos</label>
            </div>
            {urlVideos.map((urlVideo, index) =>(
                <div key={index} style={{display:'flex'}}>
                    <input type="text" className="input_edit_admin" name="urlVideo" value={urlVideo} id={`urlVideo-${index+1}`} placeholder={`urlVideo-${index+1}`} onChange={(e)=> handleChangeVideo(e, index)}/>
                    {/* <label htmlFor="urlVideo"></label> */}
                    <Button className="btn_delete_admin"
                        onClick={() => {handleDeleteInputVideo(index)}}
                        icon={  <Icon>
                                  delete
                                </Icon>}
                    />
                </div>
  
            ))}
           
          
      </div>
     
  )
  

}

export default EditVideo