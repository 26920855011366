import  firebase  from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

// Replace this with your own config details
const firebaseConfig = {
  apiKey: "AIzaSyC4czS8nHtrEGpZ6qr4deSLC31XsZIzw9I",
  authDomain: "nruprojects.firebaseapp.com",
  databaseURL: "https://nruprojects.firebaseio.com",
  projectId: "nruprojects",
  storageBucket: "nruprojects.appspot.com",
  messagingSenderId: "924129644545",
  appId: "1:924129644545:web:b1dc2f13331d9a69817000",
  measurementId: "G-7KPZ9JGPBM"
};
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ timestampsInSnapshots: true });
export const storage = firebase.storage();
export const firestore = firebase.firestore();

export default firebase 

