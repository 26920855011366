import React from 'react'
import moment from 'moment'

const ReportSummary = ({report}) => {
  return (
    <h5 className="client_list_class">{moment(report.date.toDate()).format("DD-MM-YYYY")} {report.name}</h5>   
  )
}

export default ReportSummary
