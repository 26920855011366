import React, { Component } from 'react'
import ReportListUser from '../reports-users/ReportListUser'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import NavbarUser from '../../components/layout/NavbarUser'
import FooterUser from '../../components/layout/FooterUser'
import { Icon } from 'react-materialize'



class DashboardReportsUser extends Component {
  render() {
    const { auth, idCliente, idUser} = this.props;
        if (!auth.uid || (auth.uid ==="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/' /> 
        if (idUser!==null){    
            return (
                <div className="userBody">
                    <NavbarUser/>
                    <div className="titleBody">
                        <div className="row">
                        <div className="col s8 m8 offset-m1">
                            <div className="textTitleBox">
                                <Icon>business_center</Icon>
                                <h4>PROYECTOS ACTUALES</h4>
                            </div>
                        </div>
                        </div>
                        <div className="">
                        <div className="row div_ul">
                            <ReportListUser idUser = {idUser} idCliente={idCliente}/>
                        </div>
                        </div>
                    </div>
                    <FooterUser/>
                </div>    
            )
      }else{
        return (
            <div className="userBody">
                <div className="mensajeNo">Cargando...</div>
                <FooterUser/>
            </div>
        )
      }
  }
}

const mapStateToProps = (state) => {
    const users = state.firestore.ordered.users;
    const user = users ? users[0] : null;
    const idCliente = user ? user.idCliente : null
    const idUser = user ? user.id : null
    return{
        auth: state.firebase.auth,
        user: user,
        idCliente: idCliente,
        idUser: idUser
    }
}
export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) =>[
        { collection: 'users',
        where: [
            ['uid', '==',  props.auth.uid ? props.auth.uid  : null]
        ]
      } 
    ])
  )(DashboardReportsUser)
