

export const createUser = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('users').add({
      name: user.name,
      idCliente: user.idCliente,
      uid: user.uid
      // idProject: user.idProject.id,
    }).then((docId) => {
      dispatch({ type: 'CREATE_USER_SUCCESS' });
      for(var i=0; i < user.idProject.length; i++){
        firestore.collection('users').doc(docId.id).collection('idproyects').add({
          nombre: user.idProject[i].name,
          idProject: user.idProject[i].id,
          status: false
        })  
      }   
    }).catch(err => {
      dispatch({ type: 'CREATE_USER_ERROR' }, err);
    });
  }
};

export const deleteUser = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('users').doc(user.id).delete();
  }
};

export const updateProjects = (userProject) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore.collection('users').where("uid", "==", userProject.uid).get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
          const refFirestoreProjects = firestore.collection('users').doc(doc.id).collection('idproyects');
          refFirestoreProjects.get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                for(var i=0; i < Object.keys(userProject).length; i++){
                  if(Object.keys(userProject)[i] === doc.data().nombre){               
                    refFirestoreProjects.doc(doc.id).update({
                      status: userProject[Object.keys(userProject)[i]]
                    }).then(() => {
                       dispatch({ type: 'UPDATE_PROJECT_USER' });
                    }).catch(err => {
                        dispatch({ type: 'UPDATE_PROJECT_USER_ERROR' }, err);
                    });  
                  }
                }
            });
          })
      });
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });
  }
}




