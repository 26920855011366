import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Button } from 'react-materialize'
 
 
class PilotoListUser extends Component {
    render() {
      const { projects, idCliente } = this.props;

      if ( projects){

            return (
                <div className="userBody">
                    <div className="row">
                        <div className="col s12 m4" >
                            <Link to= {{
                                pathname: `/mapa-pilotos/` + idCliente 
                            }} className="containerImg" >
                                <div className="boxImgClient">
                                    <img src={"./../img/google_maps.png"} className="proyectImg" alt="mapa proyectos"/>
                                    <div className="capaBoxImgClient"></div>
                                    <div className="textImgClient">
                                        <h5>UBICACIÓN</h5> 
                                        <p>DE PROYECTOS</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {projects.map(project => {
                            if(project.value ==="ABIERTO" && project.pilotoItem ==="yes"){
                                return (
                                    <div className="col s12 m4" key={project.id} >
                                        <Link to= {'/piloto/' + project.id} className="containerImg" >
                                            <div className="boxImgClient">
                                                <img src={project.img_project ? project.img_project : "./../img/proyecto.jpg"} className="proyectImg" alt="imagen de proyecto"/>
                                                <div className="capaBoxImgClient"></div>
                                                <div className="textImgClient">
                                                   <h5>{project.name}</h5> 
                                                   <p>{project.place}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            }
                        })}  
                    </div>
                    <div className="row boxBtn">
                        <Link to= {{
                                pathname: `/pilotos-cerrados/` + idCliente 
                            }} >
                            <div className="">
                                <Button className="btnClient">
                                Ver pilotos cerrados
                                </Button>
                            </div>
                        </Link>
                    </div>
                </div>
            )
        } else {
            return(
                <div className="userBody">
                    <div className="mensajeNo">Cargando...</div>
                </div>
            )
        }
    }
}
 
 
const mapStateToProps = (state, props) => {

    const idCliente = props.idCliente;
    const projects = state.firestore.ordered.projects;
    return{
        idCliente, idCliente,
        projects: projects
    }
}
const projects = 'projects'

export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) =>[
    { collection: 'projects', 
      where: [
        ['idCliente', '==', props.idCliente]
      ],
      storeAs: projects,
    }
    ])
  )(PilotoListUser)
 

