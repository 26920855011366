
import React, { Component } from 'react'
import PilotoListUser from '../pilotos-users/PilotoListUser'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import NavbarUser from '../../components/layout/NavbarUser'
import NavbarNoUser from '../../components/layout/NavbarNoUser'
import FooterUser from '../../components/layout/FooterUser'
import { Icon } from 'react-materialize'


class DashboardPilotoUser extends Component {
  render() {
    const { auth, idCliente} = this.props;
     if (auth.uid) { 
            return (
                <div className="userBody">
                    <NavbarUser/>
                    <div className="titleBody">
                        <div className="row">
                            <div className="col s8 m8 offset-m1">
                                <div className="textTitleBox">
                                    <Icon>camera_alt</Icon>
                                    <h4>PILOTOS ACTUALES</h4>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="row div_ul">
                                <PilotoListUser idCliente={idCliente}/>
                            </div>
                        </div>
                    </div>
                    <FooterUser/>
                </div>    
            )

     } else {
        return (
            <div className="userBody">
                 <NavbarNoUser idCliente={idCliente}/>
                <div className="titleBody">
                    <div className="row">
                        <div className="col s8 m8 offset-m1">
                            <div className="textTitleBox">
                                <Icon>camera_alt</Icon>
                                <h4>PILOTOS ACTUALES</h4>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="row div_ul">
                            <PilotoListUser  idCliente={idCliente}/>
                        </div>
                    </div>
                </div>
                <FooterUser/>
            </div>    
        )
     }
 
  }
}

const mapStateToProps = (state, ownProps) => {

    const idCliente = ownProps.match.params.id
    // const users = state.firestore.ordered.users;
    // const user = users ? users[0] : null;
    // const idCliente = user ? user.idCliente : null
    // const idUser = user ? user.id : null
    return{
        idCliente: idCliente,
        auth: state.firebase.auth
        // auth: state.firebase.auth,
        // user: user,
        // idCliente: idCliente,
        // idUser: idUser
    }
}
export default compose(
    connect(mapStateToProps)
    // firestoreConnect((props) =>[
    //     { collection: 'users',
    //     where: [
    //         ['uid', '==', props.auth.uid ? props.auth.uid  : null]
    //     ]
    //   } 
    // ])
  )(DashboardPilotoUser)

