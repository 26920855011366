import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createClient } from '../../store/actions/clientActions'
import { Redirect } from 'react-router-dom'
import Navbar from '../../components/layout/Navbar'
import Footer from '../../components/layout/Footer'

class CreateClient extends Component {
  state = {
    nameClient: '',
    logo_client:''
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value.toUpperCase(),
    })
  }

  handleChargeFile = (e) => {
    e.preventDefault();
    this.setState({ logo_client: e.target.files[0] });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.createClient(this.state);
    this.props.history.push('/dashboard');
  }
  render() {
    const { auth } = this.props;
    if (!auth.uid || (auth.uid !=="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/admin' /> 
    return (
      <div className="adminBody">
         <Navbar/>
         <div className="container">
          <div className="row">
            <div className="col s12 m10 offset-m1 add-icon">
              <form className="white" onSubmit={this.handleSubmit}>
                <i class="fas fa-city"></i>
                <h5 className="titleFormCreate">Crear un nuevo cliente</h5>
                <div className="input-field">
                  <input type="text" id='nameClient' onChange={this.handleChange} required />
                  <label htmlFor="nameClient">Nombre del Cliente</label>
                </div>
                <div className="file-field input-field">
                    <div className="btn btn-login">
                      <span>Cargar Logo</span>
                      <input type="file" onChange={this.handleChargeFile} accept="image/x-png,image/gif,image/jpeg"/>
                    </div>
                    <div className="file-path-wrapper">
                      <input className="file-path validate" readonly="readonly" type="text"  />
                      <span>Nombre del Archivo:</span>
                    </div>
                </div>
                <div className="input-field center">
                  <button className="btn btn-login">Crear</button>
                </div>
              </form>
            </div>
            </div>
          </div>
         <Footer/>
      </div>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createClient: (client) => dispatch(createClient(client))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateClient)
