import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment'

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});



const ReportSummaryList = ({reports, date}) =>  {
  const classes = useStyles();
  if(date === null){
    var actualReportYear = [];
    var actualDate = 0;
    for(var i=0; i<reports.length; i++){
        if(reports[i].date.seconds > actualDate){
            actualDate = reports[i].date.seconds
        }
    }
 
    var actualDateMoment = moment.unix(actualDate).utc().format("YYYY");

    for(var j=0; j<reports.length; j++){
      if(actualDateMoment === moment(reports[j].date.toDate()).format("YYYY")){
        actualReportYear.push(reports[j])
      }
    }
    actualReportYear.sort(function(a,b){
      return new Date(b.date.seconds) - new Date(a.date.seconds);
    });
 
 
 
 
    return (
      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell align="right">Nombre</TableCell>
              <TableCell align="right">Descargar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {actualReportYear.map(report => (
              <TableRow key={report.id}>
                <TableCell component="th" scope="row">
                  {moment(report.date.toDate()).format("YYYY-MM-DD")}
                </TableCell>
                <TableCell align="right">{report.name}</TableCell>
                <TableCell ><a href={report.zip ? report.zip : null}><i className="fas fa-file-download"></i></a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  } else {
    var reportSelected = [];
    for(var k=0; k<reports.length; k++){
      if(moment(reports[k].date.toDate()).format("YYYY") === moment(date.toDate()).format("YYYY")){
        reportSelected.push(reports[k])
      }
    }
    return (
      <Paper className={classes.root}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell align="right">Nombre</TableCell>
              <TableCell align="right">Descargar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportSelected.map(report => (
              <TableRow key={report.id}>
                <TableCell component="th" scope="row">
                  {moment(report.date.toDate()).format("YYYY-MM-DD")}
                </TableCell>
                <TableCell align="right">{report.name}</TableCell>
                <TableCell ><a href={report.zip ? report.zip : null}><i className="fas fa-file-download"></i></a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}


export default ReportSummaryList