import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
// import { Button } from 'react-materialize'
import NavbarUser from '../../components/layout/NavbarUser'
import NavbarNoUser from '../../components/layout/NavbarNoUser'
// import BasicDatePicker from './BasicDatePicker';
import FooterUser from '../layout/FooterUser'
import PilotoGallery from './PilotoGallery';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import "moment/locale/es";
import { Tabs, Tab, Collection, CollectionItem, Icon } from 'react-materialize';
import ReactPlayer from 'react-player';
import TabsPiloto from './TabsPiloto'


 
moment.locale("es");
 
class PilotoDetailsUser extends Component {
 
  
    render() {    
      const { auth, project, pilotos } = this.props;
   
      if (auth.uid) {
        if (pilotos && project) {
          return (
            <div className="userBody">
              <NavbarUser />
              <div className="ImgPage" style={{ backgroundImage: `url(${project.img_project})` }}>
                <div className="capaImgPage"></div>
                <div className="boxTextImgPage">
                  <h3>{project.name}</h3>
                  <h6>{project.place}</h6>
                </div>
              </div>
              <div className="container container_tabs" style={{ marginBottom: 100 }}>
                <TabsPiloto pilotos={pilotos} />
              </div>
              <FooterUser />
            </div>
          )
        } 

        else {
              return (
                <div className="userBody">
                  <div className="mensajeNo">Cargando...</div>
                  <FooterUser />
                </div>
              )
            }
      } 
      else {
        if(pilotos && project){
          return(
            <div className="userBody">
             <NavbarNoUser idCliente={pilotos[0].clientID}/>
              <div className="ImgPage" style={{ backgroundImage : `url(${project.img_project})` }}>
                  <div className="capaImgPage"></div>
                  <div className="boxTextImgPage">
                    <h3>{project.name}</h3>
                    <h6>{project.place}</h6>
                  </div>
              </div>
              <div className="container container_tabs" style={{ marginBottom : 100 }}>
                <TabsPiloto pilotos={pilotos}/>
              </div>
              <FooterUser/> 
          </div>
          )}
  
    else {
          return(
            <div className="userBody">
              <div className="mensajeNo">Cargando...</div>
             <FooterUser/> 
            </div>
          )
        }
      }


    }
}
 
 
const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const projects = state.firestore.data.projects;
  const project = projects ? projects[id] : null;
  const pilotosProject = state.firestore.ordered.pilotosProject;
  const pilotos = pilotosProject ? pilotosProject : null;
  // console.log(state)
  return{
      auth: state.firebase.auth,
      project: project,
      id: id,
      pilotos: pilotos
  }
}
 
const pilotos = 'pilotosProject'
 
export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) =>[
        { collection: 'pilotos',
        where: [
            ['idProject', '==', props.id]
        ],
        storeAs: pilotos,
      },
      { collection: 'projects', doc: props.id }
    ])
  )(PilotoDetailsUser)
 

