import React, { Component } from 'react'
import { connect } from 'react-redux'
import { editProject } from '../../store/actions/projectActions'
import { Redirect } from 'react-router-dom'
import Navbar from '../../components/layout/Navbar'
import Footer from '../../components/layout/Footer'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
 
class EditProject extends Component {
  state = {
    name: '',
    place: '',
    value: '',
    date: '',
    img_project:'',
    id: this.props.id,
    idCliente: this.props.idCliente,
    lat:'',
    long:''
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value.toUpperCase()
    })
  }
 
  handleChangeDate = (date) => {
    this.setState({
      date: date
    });
  }
 
  handleChargeFile = (e) => {
    e.preventDefault();
    this.setState({ img_project: e.target.files[0] });
  }
 
  handleChangeSelect = (e) => {
    this.setState({
      value: e.target.value
    });
  }
 
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.editProject(this.state);
    this.props.history.push('/proyectos/'+ this.state.idCliente);
  }
  render() {
    const { auth, project } = this.props;
    const links = project.img_project ? project.img_project : './../img/no-image.png';
    if (!auth.uid || (auth.uid !=="iAc06DPtCDM0Z7eXF1trMMjHGaz2")) return <Redirect to='/admin' /> 
    return (
      <div className="adminBody">
         <Navbar/>
         <div className="container">
         <div className="row">
            <div className="col s12 m10 offset-m1 add-icon">
            <form className="white" onSubmit={this.handleSubmit}>
              <i class="fas fa-building"></i>
              <h5 className="titleFormCreate">EDITAR {project.name}</h5>
              <div className="input-field row">
                <input type="text" id='name' onChange={this.handleChange} />
                <label htmlFor="name">{project.name}</label>
              </div>
              <div className="input-field row">
                <input type="text" id='place' onChange={this.handleChange} />
                <label htmlFor="place">{project.place}</label>
              </div>
              <div className="input-field row">
                <input type="number" id='lat' onChange={this.handleChange} step="any"/>
                <label htmlFor="lat">{project.lat ? project.lat:'Latitud'}</label>
              </div>
              <div className="input-field row">
                <input type="number" id='long' onChange={this.handleChange} step="any"/>
                <label htmlFor="long">{project.long ? project.long: 'Longitud'}</label>
              </div>
              <div className=" row">
                <select value={this.state.value} onChange={this.handleChangeSelect} className="browser-default" >
                  <option value="">{'Estado Actual: ' + project.value}</option>
                  <option value="ABIERTO">ABIERTO</option>
                  <option value="CERRADO">CERRADO</option>
                </select>            
              </div>
              <div className="input-field row">
                <DatePicker dateFormat="dd/MM/yyyy" selected={this.state.date} onChange={this.handleChangeDate}  placeholderText={project.date ? moment(project.date.toDate()).format("DD-MM-YYYY") : 'fecha'} />
              </div>
              <div className="file-field input-field">
                  <div className="btn btn-login">
                    <span>Cargar fotografía</span>
                    <input type="file" onChange={this.handleChargeFile} accept="image/x-png,image/gif,image/jpeg"/>
                  </div>
                  <div className="file-path-wrapper">
                    <input className="file-path validate" readOnly type="text"  />
                    <span>Nombre del Archivo:</span>
                  </div>
              </div>
              <div className="input-field center">
                <button className="btn btn-login">Actualizar</button>
              </div>
            </form>
            <div className="container-preview">
              <div className="row">
                <div className="col s8 offset-s2">
                  <div className="boxPreviewImg">
                    <h6>Fotografía cargada previamente</h6>
                    <img className="logo_client center" src={links} alt="sin_foto_proyecto"/>
                  </div>
                </div>
              </div>
            </div>
            </div>
            </div>
          </div>
         <Footer/>
      </div>
 
    )
  }
}
 
const mapStateToProps = (state, ownProps) => {
 
  const idCP = ownProps.match.params.id;
  var res = idCP.split("&");
  const id = res[1];
  const idCliente = res[0]
  const projects = state.firestore.data.projects;
  const project = projects ? projects[id] : null
  return {
    id : id,
    project: project,
    auth: state.firebase.auth,
    idCliente: idCliente
  }
}
 
const mapDispatchToProps = dispatch => {
  return {
    editProject: (project) => dispatch(editProject(project))
  }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(EditProject)
 

