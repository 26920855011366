

const initState = {
  image: null
}

const clientReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CREATE_CLIENT_SUCCESS':
      return state;
    case 'CREATE_CLIENT_ERROR':
      return state;
    case 'DELETE_CLIENT_SUCESS':
      return state;
    case 'DELETE_CLIENT_ERROR':
      return state;
    case 'SET_URL_LOGO':
      return state
    default:
      return state;
  }
};

export default clientReducer;