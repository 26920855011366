import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
// import EditIcon from '@material-ui/icons/Edit';


const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function BtnFlotantContact() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Fab aria-label="edit" className="contactButton">
        <i className="fas fa-envelope"></i>
      </Fab>
    </div>
  );
}
