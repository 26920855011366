import { storage } from "../../config/fbConfig";


export const createClient = (client) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore();
      firestore.collection('clients').add({
        nameClient: client.nameClient
      }).then((docId) => {
        dispatch({ type: 'CREATE_CLIENT_SUCCESS' });
        const storageRef = storage.ref("logos_clientes/")
        storageRef.child(docId.id).put(client.logo_client).then((snapshot)=> {
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            firestore.collection('clients').doc(docId.id).update({
              url_logo: downloadURL
            }).then(() => {
              dispatch({ type: 'SET_URL_LOGO' });
            })
          });
        });
      }).catch(err => {
        dispatch({ type: 'CREATE_CLIENT_ERROR' }, err);
      });
    }
  };

  export const deleteClient = (client) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore();
      firestore.collection('clients').doc(client.id).delete()
      .then(() => {
        dispatch({ type: 'DELETE_CLIENT_SUCESS' });
      }).catch(err=>{
        dispatch({ type: 'DELETE_CLIENT_ERROR' }, err);
      })
    }
  };
  
  export const editClient = (client) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore();
      if((client.nameClient !== '') && (client.logo_client !== '')) {
        firestore.collection('clients').doc(client.id).update({
          nameClient: client.nameClient
        }).then(() => {
          dispatch({ type: 'CREATE_CLIENT_SUCCESS' });
          const storageRef = storage.ref("logos_clientes/")
          storageRef.child(client.id).put(client.logo_client).then((snapshot)=> {

            snapshot.ref.getDownloadURL().then((downloadURL) => {
  
              firestore.collection('clients').doc(client.id).update({
                url_logo: downloadURL
              }).then(() => {
                dispatch({ type: 'SET_URL_LOGO' });
              })
            });
          });
        }).catch(err => {
          dispatch({ type: 'CREATE_CLIENT_ERROR' }, err);
        });  
      } else if (client.nameClient !== '') {
        firestore.collection('clients').doc(client.id).update({
          nameClient: client.nameClient
        }).then(() => {
          dispatch({ type: 'CREATE_CLIENT_SUCCESS' });
        }).catch(err => {
          dispatch({ type: 'CREATE_CLIENT_ERROR' }, err);
        });  
      } else if (client.logo_client !== ''){
        const storageRef = storage.ref("logos_clientes/")
        storageRef.child(client.id).put(client.logo_client).then((snapshot)=> {
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            firestore.collection('clients').doc(client.id).update({
              url_logo: downloadURL
            }).then(() => {
              dispatch({ type: 'SET_URL_LOGO' });
            })
          });
        });
      }
    }
  };



